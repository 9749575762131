import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, isNaN } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePrintContext } from "../../printCanvases/contextWrapper/PrintContext";
import { Button } from "react-bootstrap";

function NewLiteBridalContract({ setFormDirty }) {
  const navigate = useNavigate();
  const { setPrintData, switchToPrintPage, setShouldPrint } = usePrintContext();

  const formRef = useRef();

  const validate = () => {
    if (formRef.current.dirty) {
      setFormDirty(true);
    } else {
      setFormDirty(false);
    }
  };

  const handlePrintButtonClick = (data) => {
    setPrintData(data);
    setShouldPrint("lite-bridal");
    switchToPrintPage();
  };

  const [fetchStatus, setFetch] = useState(true);

  const [stageOne, setStageOne] = useState(true);
  const [stageTwo, setStageTwo] = useState(false);
  const [stageThree, setStageThree] = useState(false);

  const [prices, setPrices] = useState({});
  const [toplamTutar, setToplamTutar] = useState();

  const [agKesim, setAgKesim] = useState(false);
  const [prenses, setPrenses] = useState(false);
  const [gelinlikTutari, setGelinlikTutari] = useState(0);

  const [kinalikEkstra, setKinalikEkstra] = useState(false);
  const [kaftanEkstra, setKaftanEkstra] = useState(false);

  const validationSchemaStageOne = Yup.object().shape({
    damatAdi: Yup.string()
      .min(3, "Damat adı 3 karakterden az olamaz")
      .max(41, "Damat adı 40 karakterden fazla olamaz")
      .required("Damat adı gerekli"),
    damatTelefonu: Yup.string().required("Telefon numarası gerekli"),
    gelinAdi: Yup.string()
      .min(3, "Gelin adı 3 karakterden az olamaz")
      .max(41, "Gelin adı 40 karakterden fazla olamaz")
      .required("Gelin adı gerekli"),
    gelinTelefonu: Yup.string().required("Telefon numarası gerekli"),
    adres: Yup.string()
      .min(3, "Adres 3 karakterden az olamaz")
      .max(126, "Adres 125 karakterden fazla olamaz")
      .required("Adres gerekli"),
    dugunTarihi: Yup.date()

      .required("Düğün tarihi gerekli")

      .typeError("Düğün tarihi geçersiz"),
  });

  const validateDamatTelefonu = (value) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue.length !== 11) {
      return "Telefon numarası eksik olmamalıdır";
    }

    return undefined;
  };

  const validateGelinTelefonu = (value) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue.length !== 11) {
      return "Telefon numarası eksik olmamalıdır";
    }

    return undefined;
  };

  const ValidationSchemaStageTwo = Yup.object().shape({
    gelinlikModeli: Yup.mixed(),
    gelinlikSecenek: Yup.boolean().notRequired(),
    gelinlikID: Yup.string()
      .notRequired()
      .max(11, "10 karakterden fazla olamaz"),
    terzilikDetaylari: Yup.string()
      .notRequired()
      .max(101, "100 karakterden fazla olamaz"),
    gelinlikTeslimati: Yup.date()
      .notRequired()
      .typeError("Teslim tarihi geçersiz"),
    damatlikTeslimati: Yup.date()
      .notRequired()
      .typeError("Teslim tarihi geçersiz"),
    kinalikSecenek: Yup.boolean().notRequired(),
    kaftanSecenek: Yup.boolean().notRequired(),
    kinalikID: Yup.string().when("kinalikSecenek", {
      is: true,
      then: () =>
        Yup.string()
          .required("Kınalık kodu gerekli")
          .max(11, "10 karakterden fazla olamaz"),
      otherwise: () => Yup.string().notRequired(),
    }),
    kinalikKiralikBaslangic: Yup.date()
      .when("kinalikSecenek", {
        is: true,
        then: () =>
          Yup.date().min(
            new Date(new Date().setDate(new Date().getDate() - 1)),
            "Tarih geçmişe dönük olamaz",
          ),
        otherwise: () => Yup.date().notRequired(),
      })
      .when("kaftanSecenek", {
        is: true,
        then: () =>
          Yup.date().min(
            new Date(new Date().setDate(new Date().getDate() - 1)),
            "Tarih geçmişe dönük olamaz",
          ),
        otherwise: () => Yup.date().notRequired(),
      }),
    kinalikKiralikBitis: Yup.date()
      .when("kinalikSecenek", {
        is: true,
        then: () =>
          Yup.date().min(
            new Date(new Date().setDate(new Date().getDate() - 1)),
            "Tarih geçmişe dönük olamaz",
          ),
        otherwise: () => Yup.date().notRequired(),
      })
      .when("kaftanSecenek", {
        is: true,
        then: () =>
          Yup.date().min(
            new Date(new Date().setDate(new Date().getDate() - 1)),
            "Tarih geçmişe dönük olamaz",
          ),
        otherwise: () => Yup.date().notRequired(),
      }),
    kaftanID: Yup.string().when("kaftanSecenek", {
      is: true,
      then: () =>
        Yup.string()
          .required("Kaftan kodu gerekli")
          .max(11, "10 karakterden fazla olamaz"),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const ValidationSchemaStageThree = Yup.object().shape({
    idSecenek: Yup.boolean().notRequired(),
    id: Yup.string().notRequired(),
    aciklama: Yup.string()
      .max(101, "100 karakterden fazla olamaz")
      .notRequired(),
    odemeTuru: Yup.string()
      .oneOf(["Nakit", "Havale"])
      .required("Ödeme türü gerekli"),
    odemeAlinan: Yup.number()
      .test("max", "Ön ödeme toplam tutardan fazla olamaz", function (value) {
        return value <= toplamTutar;
      })
      .min(1, "Alınan ödeme tutarı sıfırdan yüksek olmalıdır")
      .required("Ödeme tutarı gerekli"),
    odemeIndirim: Yup.number()
      .notRequired()
      .test(
        "max",
        "Ödeme iskontosu toplam tutardan fazla olamaz",
        function (value) {
          if (!value) return true;
          return value <= toplamTutar;
        },
      ),
  });

  const [stageOneValues, setStageOneValues] = useState({
    damatAdi: "",
    damatTelefonu: "",
    gelinAdi: "",
    gelinTelefonu: "",
    dugunTarihi: "",
    adres: "",
  });

  const [stageTwoValues, setStageTwoValues] = useState({
    gelinlikModeli: "-",
    gelinlikID: "",
    gelinlikOzelDikim: "",
    duvakUcreti: "",
    tacUcreti: "",
    gomlekUcreti: "",
    terzilikUcreti: "",
    kaftanUcreti: "",
    kuruTemizlemeUcreti: "",
    damatlikUcreti: "",
    gelinlikTeslimati: "",
    damatlikTeslimati: "",
    terzilikDetaylari: "",
    kinalikID: "",
    kinalikKiralikBaslangic: "",
    kinalikKiralikBitis: "",
    kaftanID: "",
  });

  const [stageThreeValues, setStageThreeValues] = useState({
    id: "",
    aciklama: "",
    odemeTuru: "Nakit",
    odemeAlinan: "",
    odemeIndirim: "",
  });

  useEffect(() => {
    if (fetchStatus) {
      fetch(process.env.API_URL + "/settings", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: document.cookie.slice(6),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw response;
          }
        })
        .then((actualData) => {
          setPrices(actualData);
          setStageThreeValues({
            ...stageThreeValues,
          });
          setToplamTutar(Number(actualData.liteBosPaketFiyat));
        })
        .catch(() => {
          toast.error(
            "Fiyat bilgileri okunurken bir hata meydana geldi, lütfen tekrar deneyin",
            {
              position: "top-center",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            },
          );
        })
        .finally(() => {
          setFetch(false);
          setTimeout(() => {}, 100);
        });
    }
  }, [stageThreeValues, fetchStatus]);

  const updateTotalPrice = (values) => {
    let totalPrice = Number(toplamTutar);

    if (
      Number(values.gelinlikOzelDikim) > 0 &&
      values.gelinlikOzelDikim !== stageTwoValues.gelinlikOzelDikim &&
      values.gelinlikModeli === "Özel Dikim"
    ) {
      setGelinlikTutari(Number(values.gelinlikOzelDikim));
      if (Number(values.gelinlikOzelDikim) > stageTwoValues.gelinlikOzelDikim) {
        totalPrice +=
          Number(values.gelinlikOzelDikim) -
          Number(stageTwoValues.gelinlikOzelDikim);
      } else if (
        Number(values.gelinlikOzelDikim) < stageTwoValues.gelinlikOzelDikim
      ) {
        totalPrice -=
          Number(stageTwoValues.gelinlikOzelDikim) -
          Number(values.gelinlikOzelDikim);
      }
    } else if (
      Number(values.gelinlikOzelDikim <= 0) ||
      !values.gelinlikOzelDikim ||
      values.gelinlikModeli !== "Özel Dikim"
    ) {
      values.gelinlikOzelDikim = "";
      totalPrice -= Number(stageTwoValues.gelinlikOzelDikim);
    }

    if (
      Number(values.duvakUcreti) > 0 &&
      values.duvakUcreti !== stageTwoValues.duvakUcreti &&
      values.duvakSecenek
    ) {
      if (Number(values.duvakUcreti) > stageTwoValues.duvakUcreti) {
        totalPrice +=
          Number(values.duvakUcreti) - Number(stageTwoValues.duvakUcreti);
      } else if (Number(values.duvakUcreti) < stageTwoValues.duvakUcreti) {
        totalPrice -=
          Number(stageTwoValues.duvakUcreti) - Number(values.duvakUcreti);
      }
    } else if (
      Number(values.duvakUcreti <= 0) ||
      !values.duvakUcreti ||
      !values.duvakSecenek
    ) {
      values.duvakUcreti = "";
      totalPrice -= Number(stageTwoValues.duvakUcreti);
    }

    if (
      Number(values.tacUcreti) > 0 &&
      values.tacUcreti !== stageTwoValues.tacUcreti &&
      values.tacSecenek
    ) {
      if (Number(values.tacUcreti) > stageTwoValues.tacUcreti) {
        totalPrice +=
          Number(values.tacUcreti) - Number(stageTwoValues.tacUcreti);
      } else if (Number(values.tacUcreti) < stageTwoValues.tacUcreti) {
        totalPrice -=
          Number(stageTwoValues.tacUcreti) - Number(values.tacUcreti);
      }
    } else if (
      Number(values.tacUcreti <= 0) ||
      !values.tacUcreti ||
      !values.tacSecenek
    ) {
      values.tacUcreti = "";
      totalPrice -= Number(stageTwoValues.tacUcreti);
    }

    if (
      Number(values.terzilikUcreti) > 0 &&
      values.terzilikUcreti !== stageTwoValues.terzilikUcreti &&
      values.terzilikSecenek
    ) {
      if (Number(values.terzilikUcreti) > stageTwoValues.terzilikUcreti) {
        totalPrice +=
          Number(values.terzilikUcreti) - Number(stageTwoValues.terzilikUcreti);
      } else if (
        Number(values.terzilikUcreti) < stageTwoValues.terzilikUcreti
      ) {
        totalPrice -=
          Number(stageTwoValues.terzilikUcreti) - Number(values.terzilikUcreti);
      }
    } else if (
      Number(values.terzilikUcreti <= 0) ||
      !values.terzilikUcreti ||
      !values.terzilikSecenek
    ) {
      values.terzilikUcreti = "";
      totalPrice -= Number(stageTwoValues.terzilikUcreti);
    }

    if (
      Number(values.kaftanUcreti) > 0 &&
      values.kaftanUcreti !== stageTwoValues.kaftanUcreti &&
      values.ekstraKaftanSecenek
    ) {
      if (Number(values.kaftanUcreti) > stageTwoValues.kaftanUcreti) {
        totalPrice +=
          Number(values.kaftanUcreti) - Number(stageTwoValues.kaftanUcreti);
      } else if (Number(values.kaftanUcreti) < stageTwoValues.kaftanUcreti) {
        totalPrice -=
          Number(stageTwoValues.kaftanUcreti) - Number(values.kaftanUcreti);
      }
    } else if (
      Number(values.kaftanUcreti <= 0) ||
      !values.kaftanUcreti ||
      !values.ekstraKaftanSecenek
    ) {
      values.kaftanUcreti = "";
      totalPrice -= Number(stageTwoValues.kaftanUcreti);
    }

    if (
      Number(values.kuruTemizlemeUcreti) > 0 &&
      values.kuruTemizlemeUcreti !== stageTwoValues.kuruTemizlemeUcreti &&
      values.kuruTemizlemeSecenek
    ) {
      if (
        Number(values.kuruTemizlemeUcreti) > stageTwoValues.kuruTemizlemeUcreti
      ) {
        totalPrice +=
          Number(values.kuruTemizlemeUcreti) -
          Number(stageTwoValues.kuruTemizlemeUcreti);
      } else if (
        Number(values.kuruTemizlemeUcreti) < stageTwoValues.kuruTemizlemeUcreti
      ) {
        totalPrice -=
          Number(stageTwoValues.kuruTemizlemeUcreti) -
          Number(values.kuruTemizlemeUcreti);
      }
    } else if (
      Number(values.kuruTemizlemeUcreti <= 0) ||
      !values.kuruTemizlemeUcreti ||
      !values.kuruTemizlemeSecenek
    ) {
      values.kuruTemizlemeUcreti = "";
      totalPrice -= Number(stageTwoValues.kuruTemizlemeUcreti);
    }

    if (
      Number(values.damatlikUcreti) > 0 &&
      values.damatlikUcreti !== stageTwoValues.damatlikUcreti &&
      values.damatlikSecenek
    ) {
      if (Number(values.damatlikUcreti) > stageTwoValues.damatlikUcreti) {
        totalPrice +=
          Number(values.damatlikUcreti) - Number(stageTwoValues.damatlikUcreti);
      } else if (
        Number(values.damatlikUcreti) < stageTwoValues.damatlikUcreti
      ) {
        totalPrice -=
          Number(stageTwoValues.damatlikUcreti) - Number(values.damatlikUcreti);
      }
    } else if (
      Number(values.damatlikUcreti <= 0) ||
      !values.damatlikUcreti ||
      !values.damatlikSecenek
    ) {
      totalPrice -= Number(stageTwoValues.damatlikUcreti);
      values.damatlikUcreti = "";
    }

    setToplamTutar(totalPrice);
    setStageTwoValues(values);
  };

  const toggleStageOne = (values) => {
    if (values) {
      updateTotalPrice(values);
    }
    setStageOne(true);
    setStageTwo(false);
    setStageThree(false);
  };

  const toggleStageTwo = (values) => {
    if (values) {
      const temizDamatTel = values.damatTelefonu.replace(/\D/g, "");
      const temizGelinTel = values.gelinTelefonu.replace(/\D/g, "");
      const cleanedValues = {
        ...values,
        damatTelefonu: temizDamatTel,
        gelinTelefonu: temizGelinTel,
      };
      values = cleanedValues;
      setStageOneValues(values);
    }
    setStageTwo(true);
    setStageOne(false);
    setStageThree(false);
  };
  const backToStageTwo = (values) => {
    if (values) {
      setStageThreeValues(values);
    }
    setStageTwo(true);
    setStageOne(false);
    setStageThree(false);
  };

  const toggleStageThree = (values) => {
    if (values) {
      updateTotalPrice(values);
      setStageTwoValues(values);
    }
    setStageThree(true);
    setStageOne(false);
    setStageTwo(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    let toplamUcret = Number(toplamTutar);

    if (Number(values.odemeIndirim) > 0) {
      toplamUcret -= Number(values.odemeIndirim);
    }

    let alinanUcret = Number(toplamUcret) - Number(values.odemeAlinan);

    if (
      isNaN(Number(toplamUcret)) ||
      isNaN(Number(alinanUcret)) ||
      isNaN(Number(stageTwoValues.terzilikUcreti))
    ) {
      return toast.error(
        "Fiyat bilgileri normal gözükmüyor! Lütfen gözden geçirin veya baştan başlayın",
        {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        },
      );
    }

    setStageThreeValues(values);
    const result = await fetch(process.env.API_URL + "/liteBridalContracts", {
      method: "post",
      headers: {
        "content-type": "application/json",
        token: document.cookie.slice(6),
      },
      body: JSON.stringify({
        odemeTuru: values.odemeTuru,
        data: {
          contractID: Number(values.id.substring(3) > 0)
            ? Number(values.id.substring(3))
            : undefined,
          aciklama: values.aciklama || null,
          sozlesmeTarihi: new Date(),
          damatAdi: stageOneValues.damatAdi,
          damatTelefonu: stageOneValues.damatTelefonu,
          gelinAdi: stageOneValues.gelinAdi,
          gelinTelefonu: stageOneValues.gelinTelefonu,
          adres: stageOneValues.adres,
          dugunTarihi: new Date(stageOneValues.dugunTarihi),
          gelinlikModeli:
            stageTwoValues.gelinlikModeli === "-"
              ? "Yok"
              : stageTwoValues.gelinlikModeli,
          gelinlikTeslimati: stageTwoValues.gelinlikTeslimati
            ? new Date(stageTwoValues.gelinlikTeslimati)
            : null,
          damatlikTeslimati: stageTwoValues.damatlikTeslimati
            ? new Date(stageTwoValues.damatlikTeslimati)
            : null,
          gelinlikAgKesim: prices.gelinlikAgKesim,
          gelinlikPrenses: prices.gelinlikPrenses,
          gelinlikOzelDikim: stageTwoValues.gelinlikOzelDikim || null,
          duvakUcreti: stageTwoValues.duvakUcreti || null,
          tacUcreti: stageTwoValues.tacUcreti || null,
          gomlekUcreti: stageTwoValues.gomlekUcreti || null,
          terzilikUcreti: stageTwoValues.terzilikUcreti || null,
          kaftanUcreti: stageTwoValues.kaftanUcreti || null,
          kuruTemizlemeUcreti: stageTwoValues.kuruTemizlemeUcreti || null,
          damatlikUcreti: stageTwoValues.damatlikUcreti || null,
          gelinlikID: stageTwoValues.gelinlikID || null,
          gelinlikEkstrasi: String(gelinlikTutari),
          terzilikDetaylari: stageTwoValues.terzilikDetaylari || null,
          kinalikID: stageTwoValues.kinalikID || null,
          kaftanID: stageTwoValues.kaftanID || null,
          kinalikKiralikBaslangic: stageTwoValues.kinalikKiralikBaslangic
            ? new Date(stageTwoValues.kinalikKiralikBaslangic)
            : null,
          kinalikKiralikBitis: stageTwoValues.kinalikKiralikBitis
            ? new Date(stageTwoValues.kinalikKiralikBitis)
            : null,
          kinalikEkstrasi: prices.ekstraKaftan,
          odemeAlinan: String(values.odemeAlinan),
          odemeToplam: String(toplamUcret),
          odemeIndirim:
            Number(values.odemeIndirim) > 0
              ? String(values.odemeIndirim)
              : null,
          odemeKalan: alinanUcret,
          paketFiyati: prices.liteBosPaketFiyat,
        },
      }),
    });

    if (result.ok) {
      toast.success("Sözleşme başarıyla oluşturuldu!", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      const contract = await result.json();

      setFormDirty(false);
      handlePrintButtonClick(contract);
      await new Promise((resolve) => setTimeout(resolve, 200));

      navigate("/lite-bridal-contracts/success");
    } else if (result.status === 400) {
      toast.error("Bu sıra no ile zaten bir sözleşme var!", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else {
      toast.error("Sözleşme oluşturulurken bir hata meydana geldi!", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 500));

    setSubmitting(false);
  };

  return (
    <Fragment>
      <div className="container-xl bg-light shadow-sm p-3 my-3 border rounded-2">
        <div className="row">
          <div className="col fs-4 fw-bold">Yeni Üçlü Gelinlik Sözleşmesi</div>
          <div className="col text-end">
            <Button
              className="shadow-sm fw-bold"
              type="button"
              variant="outline-success"
              as={Link}
              to="/lite-bridal-contracts"
            >
              <i className="bi bi-house-door-fill me-1" />
              Anasayfa
            </Button>
          </div>
        </div>
      </div>

      {stageOne && (
        <Fragment>
          <div className="container-xl bg-light shadow-sm p-3 my-3 border rounded-2">
            <div className="row">
              <div className="col fs-2 fw-bold">Müşteri Bilgileri</div>
            </div>
            <Formik
              initialValues={stageOneValues}
              validationSchema={validationSchemaStageOne}
              onSubmit={toggleStageTwo}
              innerRef={formRef}
              validate={validate}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="stageOneContainer container shadow-sm p-3 mt-3 border">
                    <div className="row mt-3 justify-content-center">
                      <div className="form-floating col-lg-4 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="damatAdi"
                          name="damatAdi"
                          placeholder="Damat Adı"
                        />
                        <label className="form-label ms-3" htmlFor="damatAdi">
                          Damat Adı
                        </label>
                        <ErrorMessage
                          name="damatAdi"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                      <div className="form-floating col-lg-4 mb-3">
                        <Field
                          name="damatTelefonu"
                          placeholder="Damat Telefon No"
                          validate={validateDamatTelefonu}
                        >
                          {({ field }) => (
                            <InputMask
                              {...field}
                              mask="0 (999) 999-9999"
                              maskChar=""
                              className="form-control"
                              id="damatTelefonu"
                              name="damatTelefonu"
                              placeholder="0 (   )    -    "
                            />
                          )}
                        </Field>
                        <label
                          className="form-label ms-3"
                          htmlFor="damatTelefonu"
                        >
                          Damat Telefon No
                        </label>
                        <ErrorMessage
                          name="damatTelefonu"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                    </div>
                    <div className="row mt-3 justify-content-center">
                      <div className="form-floating col-lg-4 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="gelinAdi"
                          name="gelinAdi"
                          placeholder="Gelin adı"
                        />
                        <label className="form-label ms-3" htmlFor="gelinAdi">
                          Gelin Adı
                        </label>
                        <ErrorMessage
                          name="gelinAdi"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                      <div className="form-floating col-lg-4 mb-3">
                        <Field
                          name="gelinTelefonu"
                          placeholder="Gelin Telefon No"
                          validate={validateGelinTelefonu}
                        >
                          {({ field }) => (
                            <InputMask
                              {...field}
                              mask="0 (999) 999-9999"
                              maskChar=""
                              className="form-control"
                              id="gelinTelefonu"
                              name="gelinTelefonu"
                              placeholder="0 (___) ___-____"
                            />
                          )}
                        </Field>
                        <label
                          className="form-label ms-3"
                          htmlFor="gelinTelefonu"
                        >
                          Gelin Telefon No
                        </label>
                        <ErrorMessage
                          name="gelinTelefonu"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                    </div>

                    <div className="row mt-3 justify-content-center">
                      <div className="form-floating col-lg-4 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="adres"
                          name="adres"
                          placeholder="Adres"
                        />
                        <label className="form-label ms-3" htmlFor="adres">
                          Adres
                        </label>
                        <ErrorMessage
                          name="adres"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>

                      <div className="form-floating col-lg-4 mb-3">
                        <Field
                          type="date"
                          className="form-control"
                          id="dugunTarihi"
                          name="dugunTarihi"
                          placeholder="Düğün Tarihi"
                        />
                        <label
                          className="form-label ms-3"
                          htmlFor="Düğün Tarihi"
                        >
                          Düğün Tarihi
                        </label>
                        <ErrorMessage
                          name="dugunTarihi"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-lg text-lg-end">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="stageOneDevamBtn btn btn-primary fw-bold"
                        >
                          <i className="bi bi-arrow-right-circle-fill me-1" />
                          Devam et
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Fragment>
      )}

      {stageTwo && (
        <Fragment>
          <div className="container-xl bg-light shadow-sm p-3 my-3 border rounded-2 mb-5 ">
            <div className="row">
              <div className="col fs-2 fw-bold text-center text-md-start">
                Gelinlik Bilgileri
              </div>
              <div className="col-lg-3 ">
                <div className="shadow-lg my-2 text-center card text-bg-success fw-bold">
                  <div className="card-title fs-6 my-2">
                    Kınalık Ekstrası:{" "}
                    {kinalikEkstra && kaftanEkstra && prices.ekstraKaftan}₺
                  </div>
                </div>
              </div>
              <div className="col-lg-3 ">
                <div className="shadow-lg my-2 text-center card text-bg-success fw-bold">
                  <div className="card-title fs-6 my-2">
                    Gelinlik Tutarı: {agKesim && prices.gelinlikAgKesim}
                    {prenses && prices.gelinlikPrenses}₺
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="shadow-lg my-2 text-center card text-bg-primary fw-bold">
                  <div className="toplamTutar card-title fs-6 my-2">
                    Toplam Tutar: {toplamTutar}₺
                  </div>
                </div>
              </div>
            </div>
            <Formik
              initialValues={stageTwoValues}
              validationSchema={ValidationSchemaStageTwo}
              onSubmit={toggleStageThree}
              innerRef={formRef}
              validate={validate}
            >
              {({ isSubmitting, values, handleChange }) => (
                <Form>
                  <div className="container shadow-sm p-3 mt-3 border bg-white rounded-4">
                    <div className="container-md rounded-4 shadow-sm p-2 border bg-light">
                      <div className="row mt-3">
                        <div className="col offset-md-2 fw-bold fs-4">
                          Gelinlik & Terzilik
                        </div>
                      </div>

                      <div className="row m-3 mt-2 mb-4">
                        <div className="form-check offset-lg-2 col-lg-auto me-3 my-auto pt-3">
                          <Field
                            type="radio"
                            className="form-check-input"
                            id="gelinlikModeliHelen"
                            name="gelinlikModeli"
                            value="Helen"
                            onClick={() => {
                              if (agKesim) {
                                setToplamTutar(
                                  (prev) =>
                                    prev - Number(prices.gelinlikAgKesim),
                                );
                                setAgKesim(false);
                              }
                              if (prenses) {
                                setToplamTutar(
                                  (prev) =>
                                    prev - Number(prices.gelinlikPrenses),
                                );
                                setPrenses(false);
                              }
                              setGelinlikTutari(0);
                            }}
                          />
                          <label
                            className="form-check-label ms-2 fw-bold"
                            htmlFor="gelinlikModeliHelen"
                          >
                            Helen
                          </label>
                        </div>

                        <div className="form-check col-lg-auto me-3 my-auto pt-3">
                          <Field
                            type="radio"
                            className="form-check-input"
                            id="gelinlikModeliAgKesim"
                            name="gelinlikModeli"
                            value="Ağ Kesim"
                            onClick={() => {
                              if (prenses) {
                                setToplamTutar(
                                  (prev) =>
                                    prev - Number(prices.gelinlikPrenses),
                                );
                                setPrenses(false);
                              }
                              if (!agKesim) {
                                setToplamTutar(
                                  (prev) =>
                                    prev + Number(prices.gelinlikAgKesim),
                                );
                                setAgKesim(true);
                              }
                              setGelinlikTutari(prices.gelinlikAgKesim);
                            }}
                          />
                          <label
                            className="form-check-label ms-2 fw-bold"
                            htmlFor="gelinlikModeliAgKesim"
                          >
                            Ağ Kesim
                          </label>
                        </div>

                        <div className="form-check col-lg-auto me-3 my-auto pt-3">
                          <Field
                            type="radio"
                            className="form-check-input"
                            id="gelinlikModeliPrenses"
                            name="gelinlikModeli"
                            value="Prenses"
                            onClick={() => {
                              if (agKesim) {
                                setToplamTutar(
                                  (prev) =>
                                    prev - Number(prices.gelinlikAgKesim),
                                );
                                setAgKesim(false);
                              }
                              if (!prenses) {
                                setToplamTutar(
                                  (prev) =>
                                    prev + Number(prices.gelinlikPrenses),
                                );
                                setPrenses(true);
                              }
                              setGelinlikTutari(prices.gelinlikPrenses);
                            }}
                          />
                          <label
                            className="form-check-label ms-2 fw-bold"
                            htmlFor="gelinlikModeliPrenses"
                          >
                            Prenses
                          </label>
                        </div>

                        <div className="form-check col-lg-auto me-3 my-auto pt-3">
                          <Field
                            type="radio"
                            className="form-check-input"
                            id="gelinlikModeliDahaSonra"
                            name="gelinlikModeli"
                            value="-"
                            onClick={() => {
                              if (agKesim) {
                                setToplamTutar(
                                  (prev) =>
                                    prev - Number(prices.gelinlikAgKesim),
                                );
                                setAgKesim(false);
                              }
                              if (prenses) {
                                setToplamTutar(
                                  (prev) =>
                                    prev - Number(prices.gelinlikPrenses),
                                );
                                setPrenses(false);
                              }

                              setGelinlikTutari(0);
                            }}
                          />
                          <label
                            className="form-check-label ms-2 fw-bold"
                            htmlFor="gelinlikModeliDahaSonra"
                          >
                            Sonra Seç
                          </label>
                        </div>

                        <div className="form-check col-lg-auto me-3 my-auto pt-3">
                          <Field
                            type="radio"
                            className="form-check-input"
                            id="gelinlikModeliOzelDikim"
                            name="gelinlikModeli"
                            value="Özel Dikim"
                            onClick={() => {
                              if (agKesim) {
                                setToplamTutar(
                                  (prev) =>
                                    prev - Number(prices.gelinlikAgKesim),
                                );
                                setAgKesim(false);
                              }
                              if (prenses) {
                                setToplamTutar(
                                  (prev) =>
                                    prev - Number(prices.gelinlikPrenses),
                                );
                                setPrenses(false);
                              }
                            }}
                          />
                          <label
                            className="form-check-label ms-2 fw-bold"
                            htmlFor="gelinlikModeliOzelDikim"
                          >
                            Özel Dikim
                          </label>
                        </div>
                        <div className="form-floating col-lg-2 col-md-4 mb-3 mt-3">
                          <Field
                            name="gelinlikOzelDikim"
                            placeholder="Özel Dikim"
                          >
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="gelinlikOzelDikim"
                                mask="999999999999999"
                                maskChar={""}
                                placeholder=""
                                disabled={
                                  values.gelinlikModeli !== "Özel Dikim"
                                }
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>
                          <label
                            className="form-label ms-3"
                            htmlFor="gelinlikOzelDikim"
                          >
                            Dikim Ücreti
                          </label>

                          <ErrorMessage
                            name="gelinlikOzelDikim"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>

                      <div className="row mt-2 m-3 mb-5 justify-content-center">
                        <div className="form-check col-auto p-lg-0 pe-0 p-md-3 my-auto">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="duvakSecenek"
                            name="duvakSecenek"
                          />
                        </div>
                        <div className="form-floating col-lg-2 col-11">
                          <Field name="duvakUcreti" placeholder="Duvak">
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="duvakUcreti"
                                mask="9999999999"
                                maskChar={""}
                                placeholder=""
                                disabled={!values.duvakSecenek}
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>
                          <label
                            className="form-label ms-2"
                            htmlFor="duvakUcreti"
                          >
                            Duvak
                          </label>

                          <ErrorMessage
                            name="duvakUcreti"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-check col-auto p-lg-0 pe-0 p-md-3 my-auto ms-md-4">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="tacSecenek"
                            name="tacSecenek"
                          />
                        </div>
                        <div className="form-floating col-lg-2 col-11 mt-4 mt-md-0">
                          <Field name="tacUcreti" placeholder="Taç">
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="tacUcreti"
                                mask="9999999999"
                                maskChar={""}
                                placeholder=""
                                disabled={!values.tacSecenek}
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>
                          <label
                            className="form-label ms-2"
                            htmlFor="tacUcreti"
                          >
                            Taç
                          </label>

                          <ErrorMessage
                            name="tacUcreti"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-check col-auto p-lg-0 pe-0 p-md-3 my-auto ms-md-4">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="gomlekSecenek"
                            name="gomlekSecenek"
                          />
                        </div>
                        <div className="form-floating col-lg-2 col-11 mt-4 mt-md-0">
                          <Field name="gomlekUcreti" placeholder="Gömlek">
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="gomlekUcreti"
                                mask="9999999999"
                                maskChar={""}
                                placeholder=""
                                disabled={!values.gomlekSecenek}
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>
                          <label
                            className="form-label ms-2"
                            htmlFor="gomlekUcreti"
                          >
                            Gömlek
                          </label>

                          <ErrorMessage
                            name="gomlekUcreti"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>
                      <div className="row mt-2 m-3 mb-5 justify-content-center">
                        <div className="form-check col-auto p-lg-0 pe-0 p-md-3 my-auto">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="ekstraKaftanSecenek"
                            name="ekstraKaftanSecenek"
                          />
                        </div>
                        <div className="form-floating col-lg-2 col-11">
                          <Field name="kaftanUcreti" placeholder="Kaftan">
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="kaftanUcreti"
                                mask="9999999999"
                                maskChar={""}
                                placeholder=""
                                disabled={!values.ekstraKaftanSecenek}
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>
                          <label
                            className="form-label ms-2"
                            htmlFor="kaftanUcreti"
                          >
                            Kınalık
                          </label>

                          <ErrorMessage
                            name="kaftanUcreti"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-check col-auto p-lg-0 pe-0 p-md-3 my-auto ms-md-4">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="kuruTemizlemeSecenek"
                            name="kuruTemizlemeSecenek"
                          />
                        </div>
                        <div className="form-floating col-lg-2 col-11 mt-4 mt-md-0">
                          <Field
                            name="kuruTemizlemeUcreti"
                            placeholder="Kuru Temizleme"
                          >
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="kuruTemizlemeUcreti"
                                mask="9999999999"
                                maskChar={""}
                                placeholder=""
                                disabled={!values.kuruTemizlemeSecenek}
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>
                          <label
                            className="form-label ms-2"
                            htmlFor="kuruTemizlemeUcreti"
                          >
                            Kuru Temizleme
                          </label>

                          <ErrorMessage
                            name="kuruTemizlemeUcreti"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-check col-auto p-lg-0 pe-0 p-md-3 my-auto ms-md-4">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="damatlikSecenek"
                            name="damatlikSecenek"
                          />
                        </div>
                        <div className="form-floating col-lg-2 col-11 mt-4 mt-md-0">
                          <Field name="damatlikUcreti" placeholder="Damatlık">
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="damatlikUcreti"
                                mask="9999999999"
                                maskChar={""}
                                placeholder=""
                                disabled={!values.damatlikSecenek}
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>
                          <label
                            className="form-label ms-2"
                            htmlFor="damatlikUcreti"
                          >
                            Damatlık
                          </label>

                          <ErrorMessage
                            name="damatlikUcreti"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>

                      <div className="row mt-4 justify-content-center">
                        <div className="form-check col-auto p-lg-0 pe-0 p-md-3 my-auto ms-md-4">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="terzilikSecenek"
                            name="terzilikSecenek"
                          />
                        </div>
                        <div className="form-floating col-lg-2 col-11 mt-4 mt-md-0">
                          <Field name="terzilikUcreti" placeholder="Terzilik">
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="terzilikUcreti"
                                mask="9999999999"
                                maskChar={""}
                                placeholder=""
                                disabled={!values.terzilikSecenek}
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>
                          <label
                            className="form-label ms-2"
                            htmlFor="terzilikUcreti"
                          >
                            Terzilik
                          </label>

                          <ErrorMessage
                            name="terzilikUcreti"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-lg-2 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="gelinlikID"
                            name="gelinlikID"
                            placeholder="Gelinlik Kodu"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="gelinlikID"
                          >
                            Gelinlik Kodu
                          </label>

                          <ErrorMessage
                            name="gelinlikID"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-lg-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="terzilikDetaylari"
                            name="terzilikDetaylari"
                            placeholder="Terzilik detayları"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="terzilikDetaylari"
                          >
                            Terzilik Detayları
                          </label>
                          <ErrorMessage
                            name="terzilikDetaylari"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>
                      <div className="row mt-4 justify-content-center">
                        <div className="form-floating col-lg-3 mb-3">
                          <Field
                            type="date"
                            className="form-control"
                            id="gelinlikTeslimati"
                            name="gelinlikTeslimati"
                            placeholder="Gelinlik Teslimatı"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="gelinlikTeslimati"
                          >
                            Gelinlik Teslimatı
                          </label>

                          <ErrorMessage
                            name="gelinlikTeslimati"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-lg-3 mb-3">
                          <Field
                            type="date"
                            className="form-control"
                            id="damatlikTeslimati"
                            name="damatlikTeslimati"
                            placeholder="Damatlık Teslimatı"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="damatlikTeslimati"
                          >
                            Damatlık Teslimatı
                          </label>

                          <ErrorMessage
                            name="damatlikTeslimati"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="container-md rounded-4 shadow-sm p-2 border bg-light mt-4">
                      <div className="row mt-3">
                        <div className="col offset-md-2 fw-bold fs-4">
                          Kınalık & Kaftan
                        </div>
                      </div>
                      <div className="row m-3">
                        <div className="form-check col-md-3 offset-md-2 mb-1 my-auto">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="kinalikSecenek"
                            name="kinalikSecenek"
                            onChange={(e) => {
                              setKinalikEkstra((prev) => !prev);
                              if (e.target.checked && values.kaftanSecenek) {
                                setToplamTutar(
                                  (prev) => prev + Number(prices.ekstraKaftan),
                                );
                              } else if (
                                !e.target.checked &&
                                values.kaftanSecenek
                              ) {
                                setToplamTutar(
                                  (prev) => prev - Number(prices.ekstraKaftan),
                                );
                              }
                              handleChange(e);
                            }}
                          />
                          <label
                            className="form-label ms-2 fw-bold"
                            htmlFor="kinalikSecenek"
                          >
                            Kınalık
                          </label>
                        </div>
                        <div className="form-check col-md-3  mb-1 my-auto">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="kaftanSecenek"
                            name="kaftanSecenek"
                            onChange={(e) => {
                              setKaftanEkstra((prev) => !prev);
                              if (e.target.checked && values.kinalikSecenek) {
                                setToplamTutar(
                                  (prev) => prev + Number(prices.ekstraKaftan),
                                );
                              } else if (
                                !e.target.checked &&
                                values.kinalikSecenek
                              ) {
                                setToplamTutar(
                                  (prev) => prev - Number(prices.ekstraKaftan),
                                );
                              }
                              handleChange(e);
                            }}
                          />
                          <label
                            className="form-label ms-2 fw-bold"
                            htmlFor="kaftanSecenek"
                          >
                            Kaftan
                          </label>
                        </div>
                      </div>

                      <div className="row ps-lg-5 ms-xl-5 ms-lg-4">
                        <div className="form-floating col-lg-2 offset-lg-1 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="kinalikID"
                            name="kinalikID"
                            placeholder="Kınalık Kodu"
                            disabled={!values.kinalikSecenek}
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="kinalikID"
                          >
                            Kınalık Kodu
                          </label>

                          <ErrorMessage
                            name="kinalikID"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-lg-2 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="kaftanID"
                            name="kaftanID"
                            placeholder="Kaftan Kodu"
                            disabled={!values.kaftanSecenek}
                          />
                          <label className="form-label ms-3" htmlFor="kaftanID">
                            Kaftan Kodu
                          </label>

                          <ErrorMessage
                            name="kaftanID"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-lg-3 mb-3">
                          <Field
                            type="date"
                            className="form-control"
                            id="kinalikKiralikBaslangic"
                            name="kinalikKiralikBaslangic"
                            placeholder="Kiralama Başlangıç"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="kinalikKiralikBaslangic"
                          >
                            Kiralama Başlangıç
                          </label>

                          <ErrorMessage
                            name="kinalikKiralikBaslangic"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-lg-3 mb-3">
                          <Field
                            type="date"
                            className="form-control"
                            id="kinalikKiralikBitis"
                            name="kinalikKiralikBitis"
                            placeholder="Kınalık Kodu"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="kinalikKiralikBitis"
                          >
                            Kiralama Bitiş
                          </label>

                          <ErrorMessage
                            name="kinalikKiralikBitis"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5 mb-2">
                      <div className="col-lg text-lg-end">
                        <button
                          type="button"
                          className="btn btn-dark fw-bold me-2"
                          onClick={() => {
                            toggleStageOne(values);
                          }}
                        >
                          <i className="bi bi-arrow-left-circle-fill me-1" />
                          Geri dön
                        </button>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-primary fw-bold"
                        >
                          <i className="bi bi-arrow-right-circle-fill me-1" />
                          Devam et
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Fragment>
      )}

      {stageThree && (
        <Fragment>
          <div className="container-xl bg-light shadow-sm p-3 my-3 border rounded-2 mb-5 ">
            <div className="row">
              <div className="col fs-2 fw-bold text-center text-md-start">
                Diğer Bilgiler
              </div>
              <div className="col-md-3">
                <div className="shadow-lg my-2 text-center card text-bg-primary fw-bold">
                  <div className="card-title fs-6 my-2">
                    Toplam Tutar: {toplamTutar}₺
                  </div>
                </div>
              </div>
            </div>
            <Formik
              initialValues={stageThreeValues}
              validationSchema={ValidationSchemaStageThree}
              onSubmit={handleSubmit}
              innerRef={formRef}
              validate={validate}
            >
              {({ isSubmitting, values, handleChange }) => (
                <Form>
                  <div className="container shadow-sm p-3 mt-3 border bg-white rounded-4">
                    <div className="container-md rounded-4 shadow-sm p-2 border bg-light mt-4">
                      <div className="row mt-3">
                        <div className="col-md offset-md-2 fw-bold fs-4">
                          Sözleşme Bilgileri
                        </div>
                      </div>
                      <div className="row m-3 mb-1">
                        <div className="form-check col-md-auto offset-md-2 mb-1 my-auto">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="idSecenek"
                            name="idSecenek"
                          />
                          <label
                            className="form-label ms-2 fw-bold"
                            htmlFor="idSecenek"
                          >
                            Sözleşme Sıra No
                          </label>
                        </div>
                        <div className="form-check col-md-auto offset-md-1 mb-1 my-auto">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="aciklamaSecenek"
                            name="aciklamaSecenek"
                          />
                          <label
                            className="form-label ms-2 fw-bold"
                            htmlFor="aciklamaSecenek"
                          >
                            Açıklama & Ek Bilgiler
                          </label>
                        </div>
                      </div>
                      <div className="row m-3 mb-1">
                        <div className="form-floating col-lg-auto offset-lg-2 ps-lg-0 mb-3">
                          <Field name="id" placeholder="Sıra No">
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="id"
                                mask="150999999999999999"
                                maskChar={""}
                                placeholder="150_"
                                disabled={!values.idSecenek}
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>

                          <label
                            className="form-check-label ms-2 "
                            htmlFor="id"
                          >
                            Sıra No
                          </label>
                          <ErrorMessage
                            name="id"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-lg-5 offset-lg-0 mb-3">
                          <Field name="aciklama" placeholder="Sıra No">
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="aciklama"
                                mask=""
                                maskChar={""}
                                placeholder=""
                                disabled={!values.aciklamaSecenek}
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>

                          <label
                            className="form-check-label ms-2"
                            htmlFor="aciklama"
                          >
                            Açıklama & Ek Bilgiler
                          </label>
                          <ErrorMessage
                            name="aciklama"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="container-md rounded-4 shadow-sm p-2 border bg-light mt-4">
                      <div className="row mt-3">
                        <div className="col offset-md-2 fw-bold fs-4">
                          Ön Ödeme, İskonto ve İndirim Çeki
                        </div>
                      </div>

                      <div className="row m-3 mb-1 justify-content-center">
                        <div className="form-floating col-lg-2 mb-3">
                          <select
                            className="form-select"
                            id="odemeTuru"
                            name="odemeTuru"
                            defaultValue="Nakit"
                            onChange={handleChange}
                          >
                            <option value="Nakit">Nakit</option>
                            <option value="Havale">Havale</option>
                          </select>
                          <label
                            className="form-label ms-2 fw-bold"
                            htmlFor="gelinlikFiltresi"
                            style={{ fontSize: ".95em" }}
                          >
                            Ödeme Türü
                          </label>
                        </div>
                        <div className="form-floating col-lg-2 mb-3">
                          <Field
                            type="text"
                            name="odemeAlinan"
                            placeholder="İskonto"
                          >
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="odemeAlinan"
                                mask="9999999999"
                                maskChar={""}
                                placeholder=""
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>
                          <label
                            className="form-check-label ms-2 "
                            htmlFor="odemeAlinan"
                          >
                            Ön Ödeme
                          </label>

                          <ErrorMessage
                            name="odemeAlinan"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-lg-2 mb-3">
                          <Field
                            type="text"
                            name="odemeIndirim"
                            placeholder="İskonto"
                          >
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="odemeIndirim"
                                mask="9999999999"
                                maskChar={""}
                                placeholder=""
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>
                          <label
                            className="form-check-label ms-2 "
                            htmlFor="odemeIndirim"
                          >
                            İskonto
                          </label>
                          <ErrorMessage
                            name="odemeIndirim"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5 mb-2">
                      <div className="col-lg text-lg-end">
                        <button
                          type="button"
                          className="btn btn-dark fw-bold me-2"
                          onClick={() => {
                            backToStageTwo(values);
                          }}
                        >
                          <i className="bi bi-arrow-left-circle-fill me-1" />
                          Geri dön
                        </button>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-success shadow fw-bold"
                        >
                          <i className="bi bi-floppy-fill me-1" />
                          Kaydet ve Yazdır
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Fragment>
      )}

      <ToastContainer
        position="top-center"
        autoClose={2500}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition:Flip
      />
    </Fragment>
  );
}

export default NewLiteBridalContract;
