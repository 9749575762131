import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import turkishMoneySeperator from "../../../Utils/turkishMoneySeperator";

function WeddingLocations({ reload, setReload }) {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [updatedPrice, setUpdatedPrice] = useState({});
  const [updatedColor, setUpdatedColor] = useState({});

  useEffect(() => {
    if (reload) {
      setLoading(true);
      fetch(process.env.API_URL + "/settings/locations", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: document.cookie.slice(6),
        },
      })
        .then((response) => {
          if (response.ok) {
            setError(null);
            return response.json();
          } else {
            throw response;
          }
        })
        .then((actualData) => {
          setData(actualData);
        })
        .catch((err) => {
          if (err.status === 401) {
            setError(
              "401 - Kullanıcı yetkileri geçersiz, lütfen tekrar giriş yapınız.",
            );
          } else if (err.message === "Failed to fetch") {
            setError("Veritabanıyla iletişim kurulamadı.");
          } else
            setError(
              "Beklenmedik bir durum oluştu, lütfen biraz bekleyip tekrar deneyin.",
            );
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
            setReload(false);
          }, 150);
        });
    }
  }, [reload]);

  const updateLocation = (id, price, color) => {
    setLoading(true);
    fetch(process.env.API_URL + "/settings/locations", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        token: document.cookie.slice(6),
      },
      body: JSON.stringify({
        id: id,
        price: price,
        color: color,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setError(null);
          return response.json();
        } else {
          throw response;
        }
      })
      .then((actualData) => {
        setData(actualData);
      })
      .catch((err) => {
        if (err.status === 401) {
          setError(
            "401 - Kullanıcı yetkileri geçersiz, lütfen tekrar giriş yapınız.",
          );
        } else if (err.message === "Failed to fetch") {
          setError("Veritabanıyla iletişim kurulamadı.");
        } else
          setError(
            "Beklenmedik bir durum oluştu, lütfen biraz bekleyip tekrar deneyin.",
          );
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          setReload(true);
        }, 150);
      });
  };

  const removeLocation = (id) => {
    setLoading(true);
    fetch(process.env.API_URL + "/settings/locations", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        token: document.cookie.slice(6),
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setError(null);
          return response.json();
        } else {
          throw response;
        }
      })
      .then((actualData) => {
        setData(actualData);
      })
      .catch((err) => {
        if (err.status === 401) {
          setError(
            "401 - Kullanıcı yetkileri geçersiz, lütfen tekrar giriş yapınız.",
          );
        } else if (err.message === "Failed to fetch") {
          setError("Veritabanıyla iletişim kurulamadı.");
        } else if (err.status === 422) {
          toast.error(
            "Bu salona bağlı sözleşmeler bulunduğu için silinemiyor",
            {
              position: "top-center",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            },
          );
        } else {
          toast.error(
            "Beklenmedik bir hata oluştur, lütfen daha sonra tekrar deneyin.",
            {
              position: "top-center",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            },
          );
          setError(
            "Beklenmedik bir durum oluştu, lütfen biraz bekleyip tekrar deneyin.",
          );
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          setReload(true);
        }, 150);
      });
  };

  const handleEditClick = (id, price) => {
    setEditMode(id);
    setUpdatedPrice({ ...updatedPrice, [id]: price });
  };

  const handleCancelClick = () => {
    setEditMode(null);
  };

  const handleSaveClick = (id) => {
    updateLocation(id, updatedPrice[id], updatedColor[id]);
    setEditMode(null);
  };

  const handleColorChange = (id, color) => {
    setUpdatedColor({ ...updatedColor, [id]: color });
  };

  const handlePriceChange = (id, value) => {
    setUpdatedPrice({ ...updatedPrice, [id]: value });
  };

  const renderLocations = (locations) =>
    locations.map((location) => (
      <div className="row mb-3" key={location.id}>
        <div className="col-12">
          <div className="card mt-3 shadow-sm">
            <div className="card-body d-flex flex-row justify-content-between align-items-center">
              <div>
                <h5 className="card-title mb-1">
                  Salon Adı: {location.name}
                  {editMode !== location.id && (
                    <i
                      className="bi bi-circle-fill ms-2"
                      style={{ color: location.color }}
                    />
                  )}
                </h5>
                {editMode === location.id ? (
                  <div className="input-group mb-1">
                    <input
                      type="number"
                      className="form-control"
                      value={updatedPrice[location.id] || ""}
                      onChange={(e) =>
                        handlePriceChange(location.id, e.target.value)
                      }
                    />
                    <input
                      type="color"
                      className="form-control ms-2 my-auto"
                      value={location.color}
                      onChange={(e) =>
                        handleColorChange(location.id, e.target.value)
                      }
                      id="color"
                      name="color"
                      placeholder=""
                    />
                  </div>
                ) : (
                  <p className="card-text">
                    Ücreti: {turkishMoneySeperator(location.price)} TL
                  </p>
                )}
              </div>
              <div>
                {editMode === location.id ? (
                  <>
                    <button
                      className="btn btn-success me-2"
                      onClick={() => handleSaveClick(location.id)}
                    >
                      Kaydet
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={handleCancelClick}
                    >
                      İptal
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-primary me-2 mt-3"
                      onClick={() =>
                        handleEditClick(location.id, location.price)
                      }
                    >
                      <i className="bi bi-pen" /> Düzenle
                    </button>
                    <button
                      className="btn btn-danger mt-3"
                      onClick={() => removeLocation(location.id)}
                    >
                      Sil
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-center my-auto h-100 mt-4">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : error ? (
        <div className="row ">
          <div className="col-md-4 mx-auto text-center">
            <div className="alert alert-danger mx-0">
              <i className="bi bi-exclamation-circle-fill" />- {error}
            </div>
            <button
              className="btn btn-primary fw-bold focus-ring"
              onClick={() => {
                setReload(true);
              }}
            >
              Tekrar dene
            </button>
          </div>
        </div>
      ) : data && data.payload && data.payload?.length > 0 ? (
        <div>{renderLocations(data.payload)}</div>
      ) : (
        <div className="row">
          <div className="col-md-4 mx-auto text-center mt-4">
            <div className="alert alert-danger mx-0">
              <i className="bi bi-exclamation-circle-fill" /> Veri yok
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default WeddingLocations;
