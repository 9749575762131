import Modal from "react-bootstrap/Modal";
import React, { useEffect, useRef, useState } from "react";
import { usePrintContext } from "../../printCanvases/contextWrapper/PrintContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

function PrintContractsAsAListDaily({ toggleModal, closeModal }) {
  const [fetchStatus, setFetch] = useState(false);

  const {
    setSecondPrintData,
    setPrintContext,
    switchToSecondPrintPage,
    setSecondShouldPrint,
  } = usePrintContext();

  const handleSecondPrintButtonClick = (data, context) => {
    if (data.length > 0) {
      setSecondPrintData(data);

      setPrintContext(context);

      setSecondShouldPrint(true);

      switchToSecondPrintPage();

      closeModal();
    } else {
      toast.error(
        "Sözleşme listesi boş veya bugün hazırlanan sözleşme bulunamadı!",
        {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        },
      );
    }
  };

  const date = useRef();
  const [isToday, setIsToday] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = isToday
          ? moment
              .utc()
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .toISOString()
          : moment
              .utc(date.current.value)
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .toISOString();

        const tomorrow = isToday
          ? moment()
              .utc()
              .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
              .toISOString()
          : moment
              .utc(date.current.value)
              .set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 999,
              })
              .toISOString();

        const response = await fetch(
          `${process.env.API_URL}/calendar/daily?` +
            new URLSearchParams({
              startDate: today,
              endDate: tomorrow,
            }),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: document.cookie.slice(6),
            },
          },
        );

        if (!response.ok) {
          throw response;
        }

        const actualData = await response.json();

        handleSecondPrintButtonClick(actualData, "photo");
      } catch (err) {
        if (err.status === 401) {
          toast.error(
            "Kullanıcı yetkileri geçersiz, lütfen tekrar giriş yapınız.",
            {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            },
          );
        } else if (err.message === "Failed to fetch") {
          toast.error("Veritabanı iletişim kurulamadı.", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.error(
            "Beklenmedik bir durum oluştu, lütfen biraz bekleyip tekrar deneyin.",
            {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            },
          );
        }
      } finally {
        setFetch(false);
      }
    };

    if (fetchStatus) {
      fetchData();
    }
  }, [fetchStatus]);

  return (
    <>
      <Modal show={true} onHide={toggleModal} size={"xl"} scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-muted fs-6">Liste Yazdır</div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="py-5 my-md-5 mx-3">
          <div className="row justify-content-center">
            <div
              className="btn btn-lg btn-primary col-md-4"
              onClick={() => {
                setIsToday(true);
                date.current.value = "";
                setFetch(true);
              }}
            >
              Bugünün Listesini Yazdır
            </div>
          </div>
          <div className="row justify-content-center fs-3 border-bottom border-top my-5 py-3">
            YADA
          </div>
          <div className="row justify-content-center mb-4">
            <div
              className="col-md-4 px-0"
              // onClick={() => handleSecondPrintButtonClick(data, "photo")}
            >
              <input ref={date} type="date" className="form-control" />
            </div>
          </div>
          <div className="row justify-content-center">
            <div
              className="btn btn-lg btn-primary col-md-4"
              // onClick={() => handleSecondPrintButtonClick(data, "photo")}
              onClick={() => {
                if (date.current.value === "") {
                  toast.error("Tarih seçiniz!", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                  });
                } else {
                  setIsToday(false);
                  setFetch(true);
                }
              }}
            >
              Seçilen Tarihin Listesini Yazdır
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={750}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        transition:Flip
      />
    </>
  );
}

export default PrintContractsAsAListDaily;
