import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import React, { Fragment, useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WeddingLocations from "./WeddingLocations/WeddingLocations";
import AddLocation from "./WeddingLocations/AddLocation";

function Options({ setFormDirty }) {
  const [data, setData] = useState(false);
  const [colorData, setColorData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fetchStatus, setFetch] = useState(true);
  const [currentTab, setCurrentTab] = useState("prices");
  const [reload, setReload] = useState(true);

  const formRef = useRef();

  const validate = () => {
    if (formRef.current.dirty) {
      setFormDirty(true);
    } else {
      setFormDirty(false);
    }
  };

  useEffect(() => {
    if (fetchStatus && currentTab === "prices") {
      setLoading(true);
      fetch(process.env.API_URL + "/settings", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: document.cookie.slice(6),
        },
      })
        .then((response) => {
          if (response.ok) {
            setError(null);
            return response.json();
          } else {
            throw response;
          }
        })
        .then((actualData) => {
          setData(actualData);
        })
        .catch((err) => {
          if (err.status === 401) {
            setError(
              "401 - Kullanıcı yetkileri geçersiz, lütfen tekrar giriş yapınız.",
            );
          } else if (err.message === "Failed to fetch") {
            setError("Veritabanıyla iletişim kurulamadı.");
          } else
            setError(
              "Beklenmedik bir durum oluştu, lütfen biraz bekleyip tekrar deneyin.",
            );
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
            setFetch(false);
          }, 150);
        });
    }
  }, [fetchStatus]);

  useEffect(() => {
    if (fetchStatus && currentTab === "calendar") {
      setLoading(true);
      fetch(process.env.API_URL + "/calendar/colors", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: document.cookie.slice(6),
        },
      })
        .then((response) => {
          if (response.ok) {
            setError(null);
            return response.json();
          } else {
            throw response;
          }
        })
        .then((actualData) => {
          setColorData(actualData);
        })
        .catch((err) => {
          if (err.status === 401) {
            setError(
              "401 - Kullanıcı yetkileri geçersiz, lütfen tekrar giriş yapınız.",
            );
          } else if (err.message === "Failed to fetch") {
            setError("Veritabanıyla iletişim kurulamadı.");
          } else
            setError(
              "Beklenmedik bir durum oluştu, lütfen biraz bekleyip tekrar deneyin.",
            );
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
            setFetch(false);
          }, 150);
        });
    }
  }, [fetchStatus]);

  const ValidationSchema = Yup.object().shape({
    bosPaketFiyat: Yup.number()
      .typeError("Fiyat rakamlardan oluşmalıdır")
      .required("Fiyat gerekli")
      .test("min", "Fiyat sıfırdan büyük olmalıdır", function (value) {
        return value > 0;
      }),
    liteBosPaketFiyat: Yup.number()
      .typeError("Fiyat rakamlardan oluşmalıdır")
      .required("Fiyat gerekli")
      .test("min", "Fiyat sıfırdan büyük olmalıdır", function (value) {
        return value > 0;
      }),
    gelinlikAgKesim: Yup.number()
      .typeError("Fiyat rakamlardan oluşmalıdır")
      .required("Fiyat gerekli")
      .test("min", "Fiyat sıfırdan büyük olmalıdır", function (value) {
        return value > 0;
      }),
    gelinlikPrenses: Yup.number()
      .typeError("Fiyat rakamlardan oluşmalıdır")
      .required("Fiyat gerekli")
      .test("min", "Fiyat sıfırdan büyük olmalıdır", function (value) {
        return value > 0;
      }),
    ekstraKaftan: Yup.number()
      .typeError("Fiyat rakamlardan oluşmalıdır")
      .required("Fiyat gerekli")
      .test("min", "Fiyat sıfırdan büyük olmalıdır", function (value) {
        return value > 0;
      }),
    fotoAlbumEkstra1ucret: Yup.number()
      .typeError("Fiyat rakamlardan oluşmalıdır")
      .required("Fiyat gerekli")
      .test("min", "Fiyat sıfırdan büyük olmalıdır", function (value) {
        return value > 0;
      }),
    fotoAlbumEkstra2ucret: Yup.number()
      .typeError("Fiyat rakamlardan oluşmalıdır")
      .required("Fiyat gerekli")
      .test("min", "Fiyat sıfırdan büyük olmalıdır", function (value) {
        return value > 0;
      }),
    fotoAlbumEkstra3ucret: Yup.number()
      .typeError("Fiyat rakamlardan oluşmalıdır")
      .required("Fiyat gerekli")
      .test("min", "Fiyat sıfırdan büyük olmalıdır", function (value) {
        return value > 0;
      }),
    indirimCekiTutari: Yup.number()
      .typeError("Fiyat rakamlardan oluşmalıdır")
      .required("Fiyat gerekli")
      .test("min", "Fiyat sıfırdan büyük olmalıdır", function (value) {
        return value > 0;
      }),
    dijitalPaketFiyat: Yup.number()
      .typeError("Fiyat rakamlardan oluşmalıdır")
      .required("Fiyat gerekli")
      .test("min", "Fiyat sıfırdan büyük olmalıdır", function (value) {
        return value > 0;
      }),
    goldPaketFiyat: Yup.number()
      .typeError("Fiyat rakamlardan oluşmalıdır")
      .required("Fiyat gerekli")
      .test("min", "Fiyat sıfırdan büyük olmalıdır", function (value) {
        return value > 0;
      }),
    elitPaketFiyat: Yup.number()
      .typeError("Fiyat rakamlardan oluşmalıdır")
      .required("Fiyat gerekli")
      .test("min", "Fiyat sıfırdan büyük olmalıdır", function (value) {
        return value > 0;
      }),
    lovePaketFiyat: Yup.number()
      .typeError("Fiyat rakamlardan oluşmalıdır")
      .required("Fiyat gerekli")
      .test("min", "Fiyat sıfırdan büyük olmalıdır", function (value) {
        return value > 0;
      }),
    hikayePaketFiyat: Yup.number()
      .typeError("Fiyat rakamlardan oluşmalıdır")
      .required("Fiyat gerekli")
      .test("min", "Fiyat sıfırdan büyük olmalıdır", function (value) {
        return value > 0;
      }),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const result = await fetch(process.env.API_URL + "/settings", {
      method: "post",
      headers: {
        "content-type": "application/json",
        token: document.cookie.slice(6),
      },
      body: JSON.stringify({
        ...values,
      }),
    });

    if (result.ok) {
      toast.success("Fiyat bilgileri başarıyla kaydedildi!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else {
      toast.error("Fiyat bilgileri kaydedilirken bir hata meydana geldi!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 500));

    setSubmitting(false);
  };

  const handleColorSubmit = async (values, { setSubmitting }) => {
    const result = await fetch(process.env.API_URL + "/calendar/colors", {
      method: "post",
      headers: {
        "content-type": "application/json",
        token: document.cookie.slice(6),
      },
      body: JSON.stringify({
        ...values,
      }),
    });

    if (result.ok) {
      toast.success("Takvim bilgileri başarıyla kaydedildi!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else {
      toast.error("Takvim bilgileri kaydedilirken bir hata meydana geldi!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 500));

    setSubmitting(false);
  };

  return (
    <Fragment>
      <div className="container-md bg-light shadow-sm p-3 border my-3 rounded-2">
        <div className="row">
          <div className="col fs-4 fw-bold">Seçenekler</div>
          <div className="col text-end">
            <Button
              className="btn btn-outline-success shadow-sm fw-bold"
              variant="outline-success"
              as={Link}
              to="/"
            >
              <i className="bi bi-house-door-fill me-1" />
              Anasayfa
            </Button>
          </div>
        </div>
      </div>
      <div
        className="container-md bg-light shadow-sm p-3 border my-3 rounded-2"
        style={{ minHeight: "200px" }}
      >
        {loading ? (
          <div className="d-flex justify-content-center align-center my-auto h-100">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : error ? (
          <div className="row ">
            <div className="col-md-4 mx-auto text-center">
              <div className="alert alert-danger mx-0">
                <i className="bi bi-exclamation-circle-fill" />- {error}
              </div>
              <button
                className="btn btn-primary fw-bold focus-ring"
                onClick={() => {
                  setFetch(true);
                }}
              >
                Tekrar dene
              </button>
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="row">
              <ul className="nav nav-tabs col">
                <li className="nav-item">
                  <a
                    className={`nav-link ${currentTab === "prices" ? "active" : ""}`}
                    href="#"
                    onClick={() => {
                      if (currentTab !== "prices") {
                        setCurrentTab("prices");
                        setFetch(true);
                      }
                    }}
                  >
                    Fiyatlar
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${currentTab === "calendar" ? "active" : ""}`}
                    href="#"
                    onClick={() => {
                      if (currentTab !== "calendar") {
                        setCurrentTab("calendar");
                        setFetch(true);
                      }
                    }}
                  >
                    Takvim
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${currentTab === "weddingLocations" ? "active" : ""}`}
                    href="#"
                    onClick={() => {
                      if (currentTab !== "weddingLocations") {
                        setCurrentTab("weddingLocations");
                        setFetch(true);
                      }
                    }}
                  >
                    Salonlar
                  </a>
                </li>
                {currentTab === "weddingLocations" && (
                  <AddLocation reload={reload} setReload={setReload} />
                )}
              </ul>
            </div>
            {currentTab === "prices" && (
              <>
                <div className="row">
                  <div className="col fs-4 fw-bold mt-3">
                    Boş Paket ve İndirim Çeki Tutarı
                  </div>
                </div>
                <Formik
                  initialValues={data}
                  validationSchema={ValidationSchema}
                  onSubmit={handleSubmit}
                  innerRef={formRef}
                  validate={validate}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row mt-4 justify-content-center">
                        <div className="form-floating col-md-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="bosPaketFiyat"
                            name="bosPaketFiyat"
                            placeholder="Boş Paket Fiyatı"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="bosPaketFiyat"
                          >
                            Boş Paket Fiyatı
                          </label>
                          <ErrorMessage
                            name="bosPaketFiyat"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-md-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="liteBosPaketFiyat"
                            name="liteBosPaketFiyat"
                            placeholder="Üçlü Boş Paket Fiyatı"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="liteBosPaketFiyat"
                          >
                            Üçlü Boş Paket Fiyatı
                          </label>
                          <ErrorMessage
                            name="liteBosPaketFiyat"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-md-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="indirimCekiTutari"
                            name="indirimCekiTutari"
                            placeholder="İndirim Çeki Tutarı"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="indirimCekiTutari"
                          >
                            İndirim Çeki Tutarı
                          </label>
                          <ErrorMessage
                            name="indirimCekiTutari"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>
                      <div className="row mt-md-2">
                        <div className="col fs-4 fw-bold">
                          Gelinlik Fiyatları
                        </div>
                      </div>
                      <div className="row mt-4 justify-content-center">
                        <div className="form-floating col-md-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="gelinlikAgKesim"
                            name="gelinlikAgKesim"
                            placeholder="Ağ Kesim Gelinlik"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="gelinlikAgKesim"
                          >
                            Ağ Kesim Gelinlik
                          </label>
                          <ErrorMessage
                            name="gelinlikAgKesim"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-md-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="gelinlikPrenses"
                            name="gelinlikPrenses"
                            placeholder="Prenses Gelinlik"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="ekstraKaftan"
                          >
                            Prenses Gelinlik
                          </label>
                          <ErrorMessage
                            name="gelinlikPrenses"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-md-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="ekstraKaftan"
                            name="ekstraKaftan"
                            placeholder="Ekstra Kaftan"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="ekstraKaftan"
                          >
                            Ekstra Kaftan
                          </label>
                          <ErrorMessage
                            name="ekstraKaftan"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>

                      <div className="row mt-md-2">
                        <div className="col fs-4 fw-bold">Albüm Fiyatları</div>
                      </div>
                      <div className="row mt-4 justify-content-center">
                        <div className="form-floating col-md-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="fotoAlbumEkstra1ucret"
                            name="fotoAlbumEkstra1ucret"
                            placeholder="Klip"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="fotoAlbumEkstra1ucret"
                          >
                            Klip
                          </label>
                          <ErrorMessage
                            name="fotoAlbumEkstra1ucret"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>

                        <div className="form-floating col-md-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="fotoAlbumEkstra2ucret"
                            name="fotoAlbumEkstra2ucret"
                            placeholder="Aile Albümü"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="fotoAlbumEkstra2ucret"
                          >
                            Aile Albümü
                          </label>
                          <ErrorMessage
                            name="fotoAlbumEkstra2ucret"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>

                        <div className="form-floating col-md-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="fotoAlbumEkstra3ucret"
                            name="fotoAlbumEkstra3ucret"
                            placeholder="Kanvas"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="fotoAlbumEkstra3ucret"
                          >
                            Kanvas
                          </label>
                          <ErrorMessage
                            name="fotoAlbumEkstra3ucret"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>
                      <div className="row mt-md-2">
                        <div className="col fs-4 fw-bold">Albüm Paketleri</div>
                      </div>

                      <div className="row mt-4 justify-content-center">
                        <div className="form-floating col-md-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="dijitalPaketFiyat"
                            name="dijitalPaketFiyat"
                            placeholder="Dijital"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="dijitalPaketFiyat"
                          >
                            Dijital Paket
                          </label>
                          <ErrorMessage
                            name="dijitalPaketFiyat"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>

                        <div className="form-floating col-md-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="goldPaketFiyat"
                            name="goldPaketFiyat"
                            placeholder="Gold"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="goldPaketFiyat"
                          >
                            Gold Paket
                          </label>
                          <ErrorMessage
                            name="goldPaketFiyat"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>

                        <div className="form-floating col-md-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="elitPaketFiyat"
                            name="elitPaketFiyat"
                            placeholder="Elit"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="elitPaketFiyat"
                          >
                            Elit Paket
                          </label>
                          <ErrorMessage
                            name="elitPaketFiyat"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>

                      <div className="row mt-md-4 justify-content-center ">
                        <div className="form-floating col-md-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="lovePaketFiyat"
                            name="lovePaketFiyat"
                            placeholder="Love"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="lovePaketFiyat"
                          >
                            Love Paket
                          </label>
                          <ErrorMessage
                            name="lovePaketFiyat"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>

                        <div className="form-floating mt-3 mt-md-0 col-md-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="hikayePaketFiyat"
                            name="hikayePaketFiyat"
                            placeholder="Hikaye"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="hikayePaketFiyat"
                          >
                            Hikaye Paket
                          </label>
                          <ErrorMessage
                            name="hikayePaketFiyat"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>

                      <div className="row mt-2 mb-2">
                        <div className="col-lg text-lg-end">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-success shadow fw-bold"
                          >
                            <i className="bi bi-floppy-fill me-1" />
                            Kaydet
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </>
            )}

            {currentTab === "calendar" && (
              <>
                <div className="row">
                  <div className="col fs-4 fw-bold mt-3">Takvim Renkleri</div>
                </div>
                <Formik
                  initialValues={colorData}
                  innerRef={formRef}
                  onSubmit={handleColorSubmit}
                  validate={validate}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row mt-4 justify-content-center">
                        <div className="form-floating ps-md-5 col-md-4 mb-3">
                          <Field
                            type="color"
                            className="form-control"
                            id="extraColor"
                            name="extraColor"
                            placeholder="Albüm Ekstraları Rengi"
                          />
                          <label
                            className="form-label ms-3 ms-md-5"
                            htmlFor="extraColor"
                          >
                            Albüm Ekstraları Rengi
                          </label>
                          <ErrorMessage
                            name="extraColor"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating ps-md-5 col-md-4 mb-3">
                          <Field
                            type="color"
                            className="form-control"
                            id="hikayeColor"
                            name="hikayeColor"
                            placeholder="Hikaye Paketi Rengi"
                          />
                          <label
                            className="form-label ms-3 ms-md-5"
                            htmlFor="hikayeColor"
                          >
                            Hikaye Paketi Rengi
                          </label>
                          <ErrorMessage
                            name="hikayeColor"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>
                      <div className="row mt-2 mb-2">
                        <div className="col-lg text-lg-end">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-success shadow fw-bold"
                          >
                            <i className="bi bi-floppy-fill me-1" />
                            Kaydet
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </>
            )}
            {currentTab === "weddingLocations" && (
              <WeddingLocations reload={reload} setReload={setReload} />
            )}
          </Fragment>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition:Flip
      />
    </Fragment>
  );
}

export default Options;
