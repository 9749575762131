import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

function UpdateBridalContractModal({ toggleModal, closeModal, data }) {
  const [priceData, setPriceData] = useState(false);
  const [fetchStatus, setFetch] = useState(true);

  const formikRef = useRef();

  const handleSubmitOutsideForm = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  useEffect(() => {
    if (fetchStatus) {
      fetch(process.env.API_URL + "/settings", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: document.cookie.slice(6),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw response;
          }
        })
        .then((actualData) => {
          setPriceData(actualData);
        })
        .catch(() => {
          return null;
        })
        .finally(() => {
          setTimeout(() => {
            setFetch(false);
          }, 150);
        });
    }
  }, [fetchStatus]);

  const initialValues = {
    damatAdi: data.damatAdi,
    gelinAdi: data.gelinAdi,
    damatTelefonu: data.damatTelefonu,
    gelinTelefonu: data.gelinTelefonu,
    adres: data.adres,
    dugunTarihi:
      moment.utc(data.dugunTarihi).format("YYYY-MM-DD HH:mm") || null,
    gelinlikModeli: data.gelinlikModeli,
    gelinlikOzelDikim: data.gelinlikOzelDikim,
    duvakUcreti: data.duvakUcreti,
    tacUcreti: data.tacUcreti,
    terzilikUcreti: data.terzilikUcreti,
    kaftanUcreti: data.kaftanUcreti,
    kuruTemizlemeUcreti: data.kuruTemizlemeUcreti,
    damatlikUcreti: data.damatlikUcreti,
    gelinlikID: data.gelinlikID,
    terzilikDetaylari: data.terzilikDetaylari,
    gelinlikTeslimati:
      moment(data.gelinlikTeslimati).format("YYYY-MM-DD") || null,
    damatlikTeslimati:
      moment(data.damatlikTeslimati).format("YYYY-MM-DD") || null,
    kinalikSecenek: data.kinalikID ? true : false,
    kaftanSecenek: data.kaftanID ? true : false,
    kinalikID: data.kinalikID,
    kaftanID: data.kaftanID,
    kinalikKiralikBaslangic:
      moment(data.kinalikKiralikBaslangic).format("YYYY-MM-DD") || null,
    kinalikKiralikBitis:
      moment(data.kinalikKiralikBitis).format("YYYY-MM-DD") || null,
    fotoAlbumEkstra1: data.fotoAlbumEkstra1 ? true : false,
    fotoAlbumEkstra2: data.fotoAlbumEkstra2 ? true : false,
    fotoAlbumEkstra3: data.fotoAlbumEkstra3 ? true : false,
    albumDetaylari: data.albumDetaylari,
    albumUcreti: data.albumUcreti,
    aciklama: data.aciklama,
    fotoAlbumDisCekimTarihi:
      moment.utc(data.fotoAlbumDisCekimTarihi).format("YYYY-MM-DD HH:mm") ||
      null,
    yeniOdemeAlinan: undefined,
    odemeIndirim: data.odemeIndirim,
    indirimCekiTutari: data.indirimCekiTutari,
  };

  const ValidationSchema = Yup.object().shape({
    damatAdi: Yup.string()
      .min(3, "Damat adı 3 karakterden az olamaz")
      .max(41, "Damat adı 40 karakterden fazla olamaz")
      .required("Damat adı gerekli"),
    damatTelefonu: Yup.string().required("Telefon numarası gerekli"),
    gelinAdi: Yup.string()
      .min(3, "Gelin adı 3 karakterden az olamaz")
      .max(41, "Gelin adı 40 karakterden fazla olamaz")
      .required("Gelin adı gerekli"),
    gelinTelefonu: Yup.string().required("Telefon numarası gerekli"),
    adres: Yup.string()
      .min(3, "Adres 3 karakterden az olamaz")
      .max(126, "Adres 125 karakterden fazla olamaz")
      .required("Adres gerekli"),
    dugunTarihi: Yup.date().required("Düğün tarihi gerekli"),
    gelinlikID: Yup.string()
      .notRequired()
      .max(11, "10 karakterden fazla olamaz"),
    gelinlikOzelDikim: Yup.string().when("gelinlikModeli", {
      is: "Özel Dikim",
      then: () => Yup.string().required("Özel dikim ücreti gerekli"),
      otherwise: () => Yup.string().notRequired(),
    }),
    terzilikDetaylari: Yup.string()
      .notRequired()
      .max(101, "100 karakterden fazla olamaz"),
    kinalikID: Yup.string().when("kinalikSecenek", {
      is: true,
      then: () =>
        Yup.string()
          .required("Kınalık kodu gerekli")
          .max(11, "10 karakterden fazla olamaz"),
      otherwise: () => Yup.string().notRequired(),
    }),
    kaftanID: Yup.string().when("kaftanSecenek", {
      is: true,
      then: () =>
        Yup.string()
          .required("Kaftan kodu gerekli")
          .max(11, "10 karakterden fazla olamaz"),
      otherwise: () => Yup.string().notRequired(),
    }),
    aciklama: Yup.string()

      .max(101, "100 karakterden fazla olamaz")

      .notRequired(),
    albumDetaylari: Yup.string()
      .notRequired()
      .max(101, "100 karakterden fazla olamaz"),
    albumUcreti: Yup.string().notRequired(),
    yeniOdemeAlinan: Yup.number()
      .test("max", "Ön ödeme toplam tutardan fazla olamaz", function () {
        return newPayment <= data.odemeToplam;
      })
      .min(1, "Alınan ödeme tutarı sıfırdan yüksek olmalıdır")
      .notRequired(),
    indirimCekiTutari: Yup.number()
      .min(1, "Alınan ödeme tutarı sıfırdan yüksek olmalıdır")
      .required("İndirim çeki tutarı gerekli"),
    odemeIndirim: Yup.number()
      .notRequired()
      .test(
        "max",
        "Ödeme iskontosu toplam tutardan fazla olamaz",
        function (value) {
          if (!value) return true;
          return value <= data.odemeToplam;
        },
      ),
  });

  const validateDamatTelefonu = (value) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue.length !== 11) {
      return "Telefon numarası eksik olmamalıdır";
    }

    return undefined;
  };

  const validateGelinTelefonu = (value) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue.length !== 11) {
      return "Telefon numarası eksik olmamalıdır";
    }

    return undefined;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const temizDamatTel = values.damatTelefonu.replace(/\D/g, "");
    const temizGelinTel = values.gelinTelefonu.replace(/\D/g, "");

    const result = fetch(process.env.API_URL + "/bridalContracts", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        token: document.cookie.slice(6),
      },
      body: JSON.stringify({
        id: data.id,
        data: {
          damatAdi: values.damatAdi || null,
          gelinAdi: values.gelinAdi || null,
          adres: values.adres,
          damatTelefonu: temizDamatTel || null,
          gelinTelefonu: temizGelinTel || null,
          dugunTarihi: new Date(values.dugunTarihi) || null,
          gelinlikModeli: values.gelinlikModeli || null,
          gelinlikOzelDikim: values.gelinlikOzelDikim || null,
          duvakUcreti: values.duvakUcreti || null,
          tacUcreti: values.tacUcreti || null,
          terzilikUcreti: values.terzilikUcreti || null,
          kaftanUcreti: values.kaftanUcreti || null,
          kuruTemizlemeUcreti: values.kuruTemizlemeUcreti || null,
          damatlikUcreti: values.damatlikUcreti || null,
          gelinlikID: values.gelinlikID || null,
          terzilikDetaylari: values.terzilikDetaylari || null,
          gelinlikTeslimati: new Date(values.gelinlikTeslimati) || null,
          damatlikTeslimati: new Date(values.damatlikTeslimati) || null,
          kinalikID: values.kinalikSecenek ? values.kinalikID : null,
          kaftanID: values.kaftanSecenek ? values.kaftanID : null,
          kinalikKiralikBaslangic:
            new Date(values.kinalikTeslimatBaslangic) || null,
          kinalikKiralikBitis: new Date(values.kinalikTeslimatBitis) || null,
          fotoAlbumEkstra1: values.fotoAlbumEkstra1 || null,
          fotoAlbumEkstra1ucret: values.fotoAlbumEkstra1
            ? priceData.fotoAlbumEkstra1ucret !== data.fotoAlbumEkstra1ucret
              ? data.fotoAlbumEkstra1ucret
              : priceData.fotoAlbumEkstra1ucret
            : data.fotoAlbumEkstra1ucret,
          fotoAlbumEkstra2: values.fotoAlbumEkstra2 || null,
          fotoAlbumEkstra2ucret: values.fotoAlbumEkstra2
            ? priceData.fotoAlbumEkstra2ucret !== data.fotoAlbumEkstra2ucret
              ? data.fotoAlbumEkstra2ucret
              : priceData.fotoAlbumEkstra2ucret
            : data.fotoAlbumEkstra2ucret,
          fotoAlbumEkstra3: values.fotoAlbumEkstra3 || null,
          fotoAlbumEkstra3ucret: values.fotoAlbumEkstra3
            ? priceData.fotoAlbumEkstra3ucret !== data.fotoAlbumEkstra3ucret
              ? data.fotoAlbumEkstra3ucret
              : priceData.fotoAlbumEkstra3ucret
            : data.fotoAlbumEkstra3ucret,
          albumDetaylari: values.albumDetaylari || null,
          albumUcreti: values.albumUcreti || null,
          aciklama: values.aciklama || null,
          fotoAlbumDisCekimTarihi:
            moment.utc(values.fotoAlbumDisCekimTarihi) || null,
          odemeTuru: values.odemeTuru,
          odemeAlinan: String(newPayment),
          odemeKalan: String(Number(data.odemeToplam) - Number(newPayment)),
          odemeIndirim:
            Number(values.odemeIndirim) > 0 ? values.odemeIndirim : null,
          indirimCekiTutari: String(values.indirimCekiTutari) || null,
        },
      }),
    });

    const response = await result;

    if (response.ok) {
      toast.success("Sözleşme başarıyla güncellendi!", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else {
      toast.error("Sözleşme güncellenirken bir hata meydana geldi", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 1200));

    setSubmitting(false);
    closeModal();
  };

  const [newPayment, setNewPayment] = useState(Number(data.odemeAlinan));

  const handlePaymentChange = (e) => {
    const newPaymentValue = Number(e.target.value);
    const calculatedPayment = Number(data.odemeAlinan) + newPaymentValue;

    setNewPayment(calculatedPayment);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={toggleModal}
        size={"xl"}
        fullscreen="lg-down"
        scrollable
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-muted fs-6">
              Sözleşme Kodu: 000{data.contractID} - Toplam Fiyat:{" "}
              {data.odemeToplam}₺
            </div>
          </Modal.Title>
        </Modal.Header>

        <>
          <Modal.Body>
            <Formik
              initialValues={initialValues}
              validationSchema={ValidationSchema}
              onSubmit={handleSubmit}
              innerRef={formikRef}
            >
              {({ values, handleChange }) => (
                <Form>
                  <div className="row mb-3">
                    <div className="fs-5 fw-bold text-center">
                      Müşteri Bilgileri
                    </div>
                  </div>
                  <div className="row px-5 px-md-0 mb-1 justify-content-center">
                    <div className="form-floating col-lg-3 mb-3">
                      <Field
                        type="text"
                        className="form-control"
                        id="damatAdi"
                        name="damatAdi"
                        placeholder="Damat Adı"
                      />
                      <label className="form-label ms-3" htmlFor="damatAdi">
                        Damat Adı
                      </label>
                      <ErrorMessage
                        name="damatAdi"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-3 mb-3">
                      <Field
                        type="text"
                        className="form-control"
                        id="gelinAdi"
                        name="gelinAdi"
                        placeholder="Gelin adı"
                      />
                      <label className="form-label ms-3" htmlFor="gelinAdi">
                        Gelin Adı
                      </label>
                      <ErrorMessage
                        name="gelinAdi"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-3 mb-3">
                      <Field
                        type="text"
                        className="form-control"
                        id="adres"
                        name="adres"
                        placeholder="Adres"
                      />
                      <label className="form-label ms-3" htmlFor="adres">
                        Adres
                      </label>
                      <ErrorMessage
                        name="adres"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>
                  <div className="row px-5 px-md-0 mt-1 justify-content-center">
                    <div className="form-floating col-lg-3 mb-3">
                      <Field
                        name="damatTelefonu"
                        placeholder="Damat Telefon No"
                        validate={validateDamatTelefonu}
                      >
                        {({ field }) => (
                          <InputMask
                            {...field}
                            mask="0 (999) 999-9999"
                            maskChar=""
                            className="form-control"
                            id="damatTelefonu"
                            name="damatTelefonu"
                            placeholder="0 (   ) ___-____"
                          />
                        )}
                      </Field>
                      <label
                        className="form-label ms-3"
                        htmlFor="damatTelefonu"
                      >
                        Damat Telefon No
                      </label>
                      <ErrorMessage
                        name="damatTelefonu"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-3 mb-3">
                      <Field
                        name="gelinTelefonu"
                        placeholder="Gelin Telefon No"
                        validate={validateGelinTelefonu}
                      >
                        {({ field }) => (
                          <InputMask
                            {...field}
                            mask="0 (999) 999-9999"
                            maskChar=""
                            className="form-control"
                            id="gelinTelefonu"
                            name="gelinTelefonu"
                            placeholder="0 (___) ___-____"
                          />
                        )}
                      </Field>
                      <label
                        className="form-label ms-3"
                        htmlFor="gelinTelefonu"
                      >
                        Gelin Telefon No
                      </label>
                      <ErrorMessage
                        name="gelinTelefonu"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-3 mb-3">
                      <Field
                        type="date"
                        className="form-control"
                        id="dugunTarihi"
                        onChange={handleChange}
                        name="dugunTarihi"
                        placeholder="Düğün Tarihi"
                      />
                      <label className="form-label ms-3" htmlFor="Düğün Tarihi">
                        Düğün Tarihi
                      </label>
                      <ErrorMessage
                        name="dugunTarihi"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  <hr className="w-75 mx-auto" />

                  <div className="row">
                    <div className="fs-5 fw-bold text-center">
                      Gelinlik & Terzilik
                    </div>
                  </div>

                  <div className="row px-5 justify-content-center">
                    <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                      <Field
                        type="radio"
                        className="form-check-input"
                        id="gelinlikModeliHelen"
                        name="gelinlikModeli"
                        value="Helen"
                      />
                      <label
                        className="form-check-label ms-2 fw-bold"
                        htmlFor="gelinlikModeliHelen"
                      >
                        Helen
                      </label>
                    </div>

                    <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                      <Field
                        type="radio"
                        className="form-check-input"
                        id="gelinlikModeliAgKesim"
                        name="gelinlikModeli"
                        value="Ağ Kesim"
                      />
                      <label
                        className="form-check-label ms-2 fw-bold"
                        htmlFor="gelinlikModeliAgKesim"
                      >
                        Ağ Kesim
                      </label>
                      <label
                        className={`form-check-label ms-2 ${
                          priceData.gelinlikAgKesim !== data.gelinlikAgKesim
                            ? "text-warning"
                            : ""
                        }`}
                        htmlFor="gelinlikModeliAgKesim"
                      >
                        {priceData.gelinlikAgKesim !== data.gelinlikAgKesim
                          ? priceData.gelinlikAgKesim
                          : data.gelinlikAgKesim}
                        ₺
                      </label>
                    </div>

                    <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                      <Field
                        type="radio"
                        className="form-check-input"
                        id="gelinlikModeliPrenses"
                        name="gelinlikModeli"
                        value="Prenses"
                      />
                      <label
                        className="form-check-label ms-2 fw-bold"
                        htmlFor="gelinlikModeliPrenses"
                      >
                        Prenses
                      </label>
                      <label
                        className={`form-check-label ms-2 ${
                          priceData.gelinlikPrenses !== data.gelinlikPrenses
                            ? "text-warning"
                            : ""
                        }`}
                        htmlFor="gelinlikModeliPrenses"
                      >
                        {priceData.gelinlikPrenses !== data.gelinlikPrenses
                          ? priceData.gelinlikPrenses
                          : data.gelinlikPrenses}
                        ₺
                      </label>
                    </div>

                    <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                      <Field
                        type="radio"
                        className="form-check-input"
                        id="gelinlikModeliDahaSonra"
                        name="gelinlikModeli"
                        value="Yok"
                      />
                      <label
                        className="form-check-label ms-2 fw-bold"
                        htmlFor="gelinlikModeliDahaSonra"
                      >
                        Sonra Seç
                      </label>
                    </div>

                    <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                      <Field
                        type="radio"
                        className="form-check-input"
                        id="gelinlikModeliOzelDikim"
                        name="gelinlikModeli"
                        value="Özel Dikim"
                      />
                      <label
                        className="form-check-label ms-1 fw-bold"
                        htmlFor="gelinlikModeliOzelDikim"
                      >
                        Özel Dikim
                      </label>
                    </div>
                    <div className="form-floating col-lg-2 col-md-4 mb-3 mt-3">
                      <Field
                        name="gelinlikOzelDikim"
                        placeholder="Özel Dikim Ücreti"
                      >
                        {({ field }) => (
                          <InputMask
                            {...field}
                            type="text"
                            className="form-control"
                            id="gelinlikOzelDikim"
                            mask="999999999999999"
                            maskChar={""}
                            placeholder=""
                            disabled={values.gelinlikModeli !== "Özel Dikim"}
                            onChange={handleChange}
                          />
                        )}
                      </Field>
                      <label
                        className="form-label ms-2"
                        htmlFor="gelinlikOzelDikim"
                      >
                        Özel Dikim Ücreti
                      </label>

                      <ErrorMessage
                        name="gelinlikOzelDikim"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  <div className="row mt-1 px-5 justify-content-center">
                    <div className="mt-3 form-floating col-lg-2">
                      <Field name="duvakUcreti" placeholder="Özel Dikim Ücreti">
                        {({ field }) => (
                          <InputMask
                            type="text"
                            className="form-control"
                            id="duvakUcreti"
                            mask="9999999999"
                            maskChar={""}
                            placeholder=""
                            onChange={handleChange}
                            {...field}
                          />
                        )}
                      </Field>
                      <label className="form-label ms-3" htmlFor="duvakUcreti">
                        Duvak
                      </label>

                      <ErrorMessage
                        name="duvakUcreti"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-2 mt-3">
                      <Field name="tacUcreti" placeholder="Özel Dikim Ücreti">
                        {({ field }) => (
                          <InputMask
                            type="text"
                            className="form-control"
                            id="tacUcreti"
                            mask="9999999999"
                            maskChar={""}
                            placeholder=""
                            onChange={handleChange}
                            {...field}
                          />
                        )}
                      </Field>
                      <label className="form-label ms-3" htmlFor="tacUcreti">
                        Taç
                      </label>

                      <ErrorMessage
                        name="tacUcreti"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-2 mt-3">
                      <Field
                        name="terzilikUcreti"
                        placeholder="Özel Dikim Ücreti"
                      >
                        {({ field }) => (
                          <InputMask
                            type="text"
                            className="form-control"
                            id="terzilikUcreti"
                            mask="9999999999"
                            maskChar={""}
                            placeholder=""
                            onChange={handleChange}
                            {...field}
                          />
                        )}
                      </Field>
                      <label
                        className="form-label ms-3"
                        htmlFor="terzilikUcreti"
                      >
                        Terzilik
                      </label>

                      <ErrorMessage
                        name="terzilikUcreti"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>
                  <div className="row px-5 justify-content-center">
                    <div className="mt-3 form-floating col-lg-2">
                      <Field
                        name="kaftanUcreti"
                        placeholder="Özel Dikim Ücreti"
                      >
                        {({ field }) => (
                          <InputMask
                            type="text"
                            className="form-control"
                            id="kaftanUcreti"
                            mask="9999999999"
                            maskChar={""}
                            placeholder=""
                            onChange={handleChange}
                            {...field}
                          />
                        )}
                      </Field>
                      <label className="form-label ms-3" htmlFor="kaftanUcreti">
                        Kınalık
                      </label>

                      <ErrorMessage
                        name="kaftanUcreti"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="mt-3 form-floating col-lg-2">
                      <Field
                        name="kuruTemizlemeUcreti"
                        placeholder="Özel Dikim Ücreti"
                      >
                        {({ field }) => (
                          <InputMask
                            type="text"
                            className="form-control"
                            id="kuruTemizlemeUcreti"
                            mask="9999999999"
                            maskChar={""}
                            placeholder=""
                            onChange={handleChange}
                            {...field}
                          />
                        )}
                      </Field>
                      <label
                        className="form-label ms-3"
                        htmlFor="kuruTemizlemeUcreti"
                      >
                        Kuru Temizleme
                      </label>

                      <ErrorMessage
                        name="kuruTemizlemeUcreti"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="mt-3 form-floating col-lg-2">
                      <Field name="damatlikUcreti" placeholder="Damatlık">
                        {({ field }) => (
                          <InputMask
                            type="text"
                            className="form-control"
                            id="damatlikUcreti"
                            mask="9999999999"
                            maskChar={""}
                            placeholder=""
                            onChange={handleChange}
                            {...field}
                          />
                        )}
                      </Field>
                      <label
                        className="form-label ms-3"
                        htmlFor="damatlikUcreti"
                      >
                        Damatlık
                      </label>

                      <ErrorMessage
                        name="damatlikUcreti"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  <div className="row px-5 mt-3 px-md-0 justify-content-center">
                    <div className="form-floating col-lg-2 mb-3">
                      <Field
                        type="text"
                        className="form-control"
                        id="gelinlikID"
                        name="gelinlikID"
                        placeholder="Gelinlik Kodu"
                      />
                      <label className="form-label ms-3" htmlFor="gelinlikID">
                        Gelinlik Kodu
                      </label>

                      <ErrorMessage
                        name="gelinlikID"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-4 mb-3">
                      <Field
                        type="text"
                        className="form-control"
                        id="terzilikDetaylari"
                        name="terzilikDetaylari"
                        placeholder="Terzilik detayları"
                      />
                      <label
                        className="form-label ms-3"
                        htmlFor="terzilikDetaylari"
                      >
                        Terzilik Detayları
                      </label>
                      <ErrorMessage
                        name="terzilikDetaylari"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  <div className="row mt-2 px-md-0 justify-content-center">
                    <div className="form-floating col-lg-3 mb-3">
                      <Field
                        type="date"
                        className="form-control"
                        id="gelinlikTeslimati"
                        name="gelinlikTeslimati"
                        placeholder="Gelinlik Teslimatı"
                      />
                      <label
                        className="form-label ms-3"
                        htmlFor="gelinlikTeslimati"
                      >
                        Gelinlik Teslimatı
                      </label>

                      <ErrorMessage
                        name="gelinlikTeslimati"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-3 mb-3">
                      <Field
                        type="date"
                        className="form-control"
                        id="damatlikTeslimati"
                        name="damatlikTeslimati"
                        placeholder="Damatlık Teslimatı"
                      />
                      <label
                        className="form-label ms-3"
                        htmlFor="damatlikTeslimati"
                      >
                        Damatlık Teslimatı
                      </label>

                      <ErrorMessage
                        name="damatlikTeslimati"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  <hr className="w-75 mx-auto" />

                  <div className="row">
                    <div className="fs-5 fw-bold text-center">
                      Kınalık & Kaftan
                    </div>
                  </div>

                  <div className="row m-3 justify-content-center">
                    <div className="form-check col-md-auto mb-1 my-auto">
                      <Field
                        type="checkbox"
                        className="form-check-input"
                        id="kinalikSecenek"
                        name="kinalikSecenek"
                      />
                      <label
                        className="form-label ms-2 fw-bold"
                        htmlFor="kinalikSecenek"
                      >
                        Kınalık
                      </label>
                    </div>
                    <div className="form-check col-md-auto mb-1 my-auto">
                      <Field
                        type="checkbox"
                        className="form-check-input"
                        id="kaftanSecenek"
                        name="kaftanSecenek"
                      />
                      <label
                        className="form-label ms-2 fw-bold"
                        htmlFor="kaftanSecenek"
                      >
                        Kaftan
                      </label>

                      <label
                        className={`form-label ms-2 ${
                          priceData.ekstraKaftan !== data.kinalikEkstrasi
                            ? "text-warning"
                            : ""
                        }`}
                        htmlFor="kaftanUcreti"
                      >
                        (Ekstra){" "}
                        {priceData.ekstraKaftan !== data.kinalikEkstrasi
                          ? data.kinalikEkstrasi
                          : priceData.ekstraKaftan}
                        ₺
                      </label>
                    </div>
                  </div>

                  <div className="row px-5 px-md-0 justify-content-center">
                    <div className="form-floating col-lg-2 mb-3">
                      <Field
                        type="text"
                        className="form-control"
                        id="kinalikID"
                        name="kinalikID"
                        placeholder="Kınalık Kodu"
                        disabled={!values.kinalikSecenek}
                      />
                      <label className="form-label ms-3" htmlFor="kinalikID">
                        Kınalık Kodu
                      </label>

                      <ErrorMessage
                        name="kinalikID"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-2 mb-3">
                      <Field
                        type="text"
                        className="form-control"
                        id="kaftanID"
                        name="kaftanID"
                        placeholder="Kaftan Kodu"
                        disabled={!values.kaftanSecenek}
                      />
                      <label className="form-label ms-3" htmlFor="kaftanID">
                        Kaftan Kodu
                      </label>

                      <ErrorMessage
                        name="kaftanID"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-2 mb-3">
                      <Field
                        type="date"
                        className="form-control"
                        id="kinalikKiralikBaslangic"
                        name="kinalikKiralikBaslangic"
                        placeholder="Kiralama Başlangıç"
                      />
                      <label
                        className="form-label ms-3"
                        htmlFor="kinalikKiralikBaslangic"
                      >
                        Kiralama Başlangıç
                      </label>

                      <ErrorMessage
                        name="kinalikKiralikBaslangic"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-2 mb-3">
                      <Field
                        type="date"
                        className="form-control"
                        id="kinalikKiralikBitis"
                        name="kinalikKiralikBitis"
                        placeholder="Kınalık Kodu"
                      />
                      <label
                        className="form-label ms-3"
                        htmlFor="kinalikKiralikBitis"
                      >
                        Kiralama Bitiş
                      </label>

                      <ErrorMessage
                        name="kinalikKiralikBitis"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  <hr className="w-75 mx-auto" />

                  <div className="row mb-3">
                    <div className="fs-5 fw-bold text-center">
                      Albüm Bilgileri
                    </div>
                  </div>

                  <div className="row px-5 justify-content-center mb-1">
                    <div className="form-check col-md-auto mb-3">
                      <Field
                        type="checkbox"
                        className="form-check-input"
                        id="fotoAlbumEkstra1"
                        name="fotoAlbumEkstra1"
                      />
                      <label
                        className="form-check-label ms-2 fw-bold"
                        htmlFor="fotoAlbumEkstra1"
                      >
                        Klip
                      </label>
                      <label
                        className={`form-check-label ms-2 ${
                          priceData.fotoAlbumEkstra1ucret !==
                          data.fotoAlbumEkstra1ucret
                            ? "text-warning"
                            : "text-normal"
                        }`}
                        htmlFor="fotoAlbumEkstra1"
                      >
                        {priceData.fotoAlbumEkstra1ucret !==
                        data.fotoAlbumEkstra1ucret
                          ? priceData.fotoAlbumEkstra1ucret
                          : data.fotoAlbumEkstra1ucret}
                        ₺
                      </label>
                    </div>

                    <div className="form-check col-md-auto mb-3">
                      <Field
                        type="checkbox"
                        className="form-check-input"
                        id="fotoAlbumEkstra2"
                        name="fotoAlbumEkstra2"
                      />
                      <label
                        className="form-check-label ms-2 fw-bold"
                        htmlFor="fotoAlbumEkstra2"
                      >
                        Aile Albümü
                      </label>
                      <label
                        className={`form-check-label ms-2 ${
                          priceData.fotoAlbumEkstra2ucret !==
                          data.fotoAlbumEkstra2ucret
                            ? "text-warning"
                            : "text-normal"
                        }`}
                        htmlFor="fotoAlbumEkstra2"
                      >
                        {priceData.fotoAlbumEkstra2ucret !==
                        data.fotoAlbumEkstra2ucret
                          ? priceData.fotoAlbumEkstra2ucret
                          : data.fotoAlbumEkstra2ucret}
                        ₺
                      </label>
                    </div>

                    <div className="form-check col-md-auto ps-md-4 mb-3">
                      <Field
                        type="checkbox"
                        className="form-check-input"
                        id="fotoAlbumEkstra3"
                        name="fotoAlbumEkstra3"
                      />
                      <label
                        className="form-check-label ms-2 fw-bold"
                        htmlFor="fotoAlbumEkstra3"
                      >
                        Kanvas
                      </label>
                      <label
                        className={`form-check-label ms-2 ${
                          priceData.fotoAlbumEkstra3ucret !==
                          data.fotoAlbumEkstra3ucret
                            ? "text-warning"
                            : "text-normal"
                        }`}
                        htmlFor="fotoAlbumEkstra3"
                      >
                        {priceData.fotoAlbumEkstra3ucret !==
                        data.fotoAlbumEkstra3ucret
                          ? priceData.fotoAlbumEkstra3ucret
                          : data.fotoAlbumEkstra3ucret}
                        ₺
                      </label>
                    </div>
                  </div>

                  <div className="row px-5 px-md-0 mb-1">
                    <div className="form-floating col-lg-5 offset-lg-2 mb-3">
                      <Field
                        type="text"
                        className="form-control"
                        id="albumDetaylari"
                        name="albumDetaylari"
                        placeholder="Albüm Ekstrası"
                      />
                      <label
                        className="form-check-label ms-2 "
                        htmlFor="albumDetaylari"
                      >
                        Albüm Detayları
                      </label>

                      <ErrorMessage
                        name="albumDetaylari"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-3 mb-3">
                      <Field
                        type="text"
                        name="albumUcreti"
                        placeholder="İskonto"
                      >
                        {({ field }) => (
                          <InputMask
                            type="text"
                            className="form-control"
                            id="albumUcreti"
                            mask="9999999999"
                            maskChar={""}
                            placeholder=""
                            onChange={handleChange}
                            {...field}
                          />
                        )}
                      </Field>
                      <label
                        className="form-check-label ms-2 "
                        htmlFor="albumUcreti"
                      >
                        Albüm Ekstra Ücreti
                      </label>
                      <ErrorMessage
                        name="albumUcreti"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  <hr className="w-75 mx-auto" />

                  <div className="row mb-3">
                    <div className="fs-5 fw-bold text-center">
                      Açıklama ve Dış Çekim Tarihi
                    </div>
                  </div>

                  <div className="row mb-1 px-5 px-md-0 justify-content-center">
                    <div className="form-floating col-lg-5 mb-3">
                      <Field name="aciklama" placeholder="Sıra No">
                        {({ field }) => (
                          <InputMask
                            type="text"
                            className="form-control"
                            id="aciklama"
                            mask=""
                            maskChar={""}
                            placeholder=""
                            onChange={handleChange}
                            {...field}
                          />
                        )}
                      </Field>

                      <label
                        className="form-check-label ms-2"
                        htmlFor="aciklama"
                      >
                        Açıklama & Ek Bilgiler
                      </label>
                      <ErrorMessage
                        name="aciklama"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>

                    <div className="form-floating col-md-3 mb-3">
                      <Field
                        type="datetime-local"
                        className="form-control"
                        id="fotoAlbumDisCekimTarihi"
                        name="fotoAlbumDisCekimTarihi"
                      />
                      <label
                        className="form-check-label ms-2 fw-bold"
                        style={{ fontSize: ".8em" }}
                        htmlFor="fotoAlbumDisCekimTarihi"
                      >
                        Dış Çekim Tarihi
                      </label>
                      <ErrorMessage
                        name="fotoAlbumDisCekimTarihi"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  <hr className="w-75 mx-auto" />

                  <div className="row mb-3">
                    <div className="fs-5 fw-bold text-center">
                      Ön Ödeme ve İskonto
                    </div>

                    <div className="text-center mt-2 text-muted">
                      Toplam Alınan Ödeme: {newPayment}₺
                    </div>
                  </div>

                  <div className="row mb-1 px-5 px-md-0 justify-content-center">
                    <div className="form-floating col-lg-2 mb-3">
                      <select
                        className="form-select"
                        id="odemeTuru"
                        name="odemeTuru"
                        defaultValue="Nakit"
                        onChange={handleChange}
                      >
                        <option value="Nakit">Nakit</option>
                        <option value="Havale">Havale</option>
                      </select>
                      <label
                        className="form-label ms-2 fw-bold"
                        htmlFor="gelinlikFiltresi"
                        style={{ fontSize: ".95em" }}
                      >
                        Ödeme Türü
                      </label>
                    </div>
                    <div className="form-floating col-lg-2 mb-3">
                      <Field
                        type="text"
                        name="yeniOdemeAlinan"
                        placeholder="Yeni Ön Ödeme"
                      >
                        {({ field }) => (
                          <InputMask
                            {...field}
                            type="text"
                            className="form-control"
                            id="yeniOdemeAlinan"
                            mask="9999999999"
                            maskChar={""}
                            placeholder=""
                            onChange={(e) => {
                              handlePaymentChange(e);
                              handleChange(e);
                            }}
                          />
                        )}
                      </Field>
                      <label
                        className="form-check-label ms-2 "
                        htmlFor="yeniOdemeAlinan"
                      >
                        Yeni Ön Ödeme
                      </label>

                      <ErrorMessage
                        name="yeniOdemeAlinan"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-2 mb-3">
                      <Field
                        type="text"
                        name="odemeIndirim"
                        placeholder="İskonto"
                      >
                        {({ field }) => (
                          <InputMask
                            type="text"
                            className="form-control"
                            id="odemeIndirim"
                            mask="9999999999"
                            maskChar={""}
                            placeholder=""
                            onChange={handleChange}
                            {...field}
                          />
                        )}
                      </Field>
                      <label
                        className="form-check-label ms-2 "
                        htmlFor="odemeIndirim"
                      >
                        İskonto
                      </label>
                      <ErrorMessage
                        name="odemeIndirim"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                    <div className="form-floating col-lg-2 mb-3">
                      <Field
                        type="text"
                        name="indirimCekiTutari"
                        placeholder="İskonto"
                      >
                        {({ field }) => (
                          <InputMask
                            type="text"
                            className="form-control"
                            id="indirimCekiTutari"
                            mask="9999999999"
                            maskChar={""}
                            placeholder=""
                            onChange={handleChange}
                            {...field}
                          />
                        )}
                      </Field>
                      <label
                        className="form-check-label ms-2 "
                        htmlFor="indirimCekiTutari"
                      >
                        İndirim Çeki
                      </label>

                      <ErrorMessage
                        name="indirimCekiTutari"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  <hr className="w-75 mx-auto" />
                  <div className="row mt-3 px-md-0 px-4 justify-content-center">
                    <div
                      className="alert alert-warning col-lg-9 px-4 py-3"
                      role="alert"
                    >
                      <i className="bi bi-info-circle-fill me-2" />
                      Sözleşmeyi oluştururken girilen fiyat bilgileriyle
                      (gelinlik modeli fiyatları, ekstra kaftan, albüm
                      seçenekleri) güncel fiyat bilgileri aynı değilse, fiyatlar
                      sarı renkle gösterilir ve sözleşmeyi güncellediğinizde bu
                      fiyatlar değişmez. Fiyatı değiştirmek için başka bir
                      seçenek seçmelisiniz.
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              İptal Et
            </Button>
            <Button variant="primary" onClick={handleSubmitOutsideForm}>
              Güncelle
            </Button>
          </Modal.Footer>
        </>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={450}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        transition:Flip
      />
    </>
  );
}

export default UpdateBridalContractModal;
