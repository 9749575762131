import React, { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import "./NewPrintCanvas.css";
import { usePrintContext } from "../contextWrapper/PrintContext";

export default function NewWeddingPrintCanvas() {
  const { printData, switchBackToReality, shouldPrint, setShouldPrint } =
    usePrintContext();

  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (shouldPrint === "wedding") {
      handlePrint();

      switchBackToReality();

      setShouldPrint(false);
    }
  }, [
    printData,
    shouldPrint,
    handlePrint,
    switchBackToReality,
    setShouldPrint,
  ]);

  return (
    <>
      <div className="container-fluid bg-white border mt-3 d-none">
        <div ref={componentRef} className="" id="printCanvas">
          <div className="row">
            <div className="col mt-5 ps-0 pe-0 me-0">
              <div className="col text-center fs-1 fw-bold">
                MİSS LUNA & ALTINPARK
              </div>
              <div
                className="col text-center fs-2 fw-bold"
                style={{ lineHeight: "2em" }}
              >
                CAMERAY PHOTOGRAPHY
              </div>
              <div
                className="col text-center mb-2"
                style={{ fontSize: "1.2em" }}
              >
                DÜĞÜN PAKETİ SÖZLEŞMESİ
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col ms-4">
              <b className="pe-1">SÖZLEŞME TARİHİ:</b>
              {moment(printData.sozlesmeTarihi).format("DD.MM.YYYY")}
            </div>
            <div className="col text-end me-4">
              <b className="pe-1">SIRA NO:</b>
              120{printData.contractID}
            </div>
          </div>

          <hr className="mx-4 my-2" />

          <div className="row">
            <div className="col-3 ms-4 ps-4 me-2 pe-0 border-end">
              {/*MÜŞTERİ BİLGİLERİ BAŞLANGIÇ*/}
              <div className="row ps-2" style={{ lineHeight: "1.55em" }}>
                <div className="row fw-bold fs-5">MÜŞTERİ</div>
                <div className="row fw-bold fs-5">BİLGİLERİ</div>
              </div>
              <div
                className="row mt- pt-4"
                style={{
                  fontSize: ".85em",
                  letterSpacing: "0px",
                  lineHeight: "1.45em",
                }}
              >
                <div className="row ps-3 pe-0">
                  <div className="row">
                    <div className="col ps-3">DAMAT</div>
                  </div>
                  <div className="row">
                    <div className="col fw-bold">İSİM SOYİSİM:</div>
                  </div>
                  <div
                    className="row ps-4 pt-1 pe-0"
                    style={{ height: "50px" }}
                  >
                    {printData.damatAdi}
                  </div>
                  <div className="row">
                    <div className="col">TELEFON:</div>
                  </div>
                  <div className="row ps-4 pe0" style={{ height: "40px" }}>
                    {printData.damatTelefonu}
                  </div>
                  <div className="row">
                    <div className="col ps-3">GELİN</div>
                  </div>
                  <div className="row">
                    <div className="col fw-bold">İSİM SOYİSİM:</div>
                  </div>
                  <div className="row ps-4 pe-0" style={{ height: "45px" }}>
                    {printData.gelinAdi}
                  </div>

                  <div className="row">
                    <div className="col">TELEFON:</div>
                  </div>
                  <div className="row ps-4 pe-0" style={{ height: "35px" }}>
                    {printData.gelinTelefonu}
                  </div>

                  <div className="row">
                    <div className="col">DÜĞÜN TARİHİ:</div>
                  </div>
                  <div className="row ps-4 pe-0" style={{ height: "35px" }}>
                    {moment(printData.dugunTarihi).format("DD.MM.YYYY")}
                  </div>
                  <div className="row">
                    <div className="col fw-bold" style={{ fontSize: "1.25em" }}>
                      ADRES:
                    </div>
                  </div>
                  <div
                    className="row ps-4 pe-0"
                    style={{ height: "95px", lineHeight: "1.35em" }}
                  >
                    {printData.adres}
                  </div>
                </div>
                <hr className="" style={{ width: "75%" }} />
                <div className="row">
                  <div className="col fw-bold" style={{ fontSize: "1.35em" }}>
                    İRTİBAT:
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col fw-bold pt-2"
                    style={{
                      height: "100px",
                      fontSize: "1.35em",
                      letterSpacing: ".8px",
                    }}
                  >
                    05540161638
                  </div>
                </div>
                <hr className="" style={{ width: "75%" }} />
                <div className="row">
                  <div className="col fw-bold fs-5 text-center py-2">
                    ALTINPARK
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col fw-bold pt-2 pb-2"
                    style={{ fontSize: "1.25em" }}
                  >
                    ADRES:
                  </div>
                </div>
                <div className="row ps-4 fw-bold mb-2">
                  GÖKÇE ÖREN MAH. ESKİ ORHANELİ YOLU 6.KM OSMANGAZİ/BURSA
                </div>
                <hr className="" style={{ width: "75%" }} />
              </div>
              {/*MÜŞTERİ BİLGİLERİ BİTİŞ*/}
            </div>
            <div
              className="col ms-1 d-flex flex-column"
              style={{ fontSize: ".95em" }}
            >
              {printData.dugunSalonu && (
                <>
                  <div className="row ps-2">
                    {/*DÜĞÜN SALONU BİLGİLERİ BAŞLANGIÇ*/}
                    <div
                      className="col fw-bold mt-3"
                      style={{ fontSize: "1.1em" }}
                    >
                      • DÜĞÜN BİLGİLERİ
                    </div>
                  </div>
                  <div className="row ms-1 mt-1">
                    <div className="col-auto fw-bold ps-2 pe-1 mt-auto">
                      SALON ADI:
                    </div>
                    <div className="col-auto ps-0 pe-1">
                      {printData.dugunSalonu.name}
                    </div>
                  </div>
                  <div className="row ms-1 mt-1">
                    <div className="col-auto fw-bold ps-2 pe-1 mt-auto">
                      SALON ÜCRETİ:
                    </div>
                    <div className="col-auto ps-0 pe-1">
                      {printData.dugunSalonu.price} ₺
                    </div>
                  </div>
                  <div className="row ms-1 mt-1">
                    <div className="col-auto fw-bold ps-2 pe-1 mt-auto">
                      DÜĞÜN TARİHİ:
                    </div>
                    <div className="col-auto ps-0 pe-1">
                      {printData.dugunTarihi
                        ? moment
                            .utc(printData.dugunTarihi)
                            .format("YYYY-MM-DD HH:mm")
                        : "Belirtilmedi"}
                    </div>
                  </div>
                  <div className="row ms-1 mt-1">
                    <div className="col-auto fw-bold ps-2 pe-1 mt-auto">
                      KİŞİ SAYISI:
                    </div>
                    <div className="col-auto ps-0 pe-1">
                      {printData.kisiSayisi}
                    </div>
                  </div>
                </>
              )}
              <div className="row ps-2">
                {/*DÜĞÜN SALONU BİLGİLERİ BAŞLANGIÇ*/}
                <div className="col fw-bold mt-3" style={{ fontSize: "1.1em" }}>
                  • PAKET
                </div>
              </div>
              <div className="row ms-1 mt-1">
                <div className="col-auto fw-bold ps-2 pe-1 mt-auto">
                  PAKET TUTARI:
                </div>
                <div className="col-auto ps-0 pe-1">
                  {printData.paketFiyati} ₺
                </div>
              </div>
              <div className="row ms-1 mt-1">
                <div className="col-auto fw-bold ps-2 pe-1 mt-auto">
                  EK ÜCRET:
                </div>
                <div className="col-auto ps-0 pe-1">
                  {printData.odemeEkstra || "-"} ₺
                </div>
              </div>
              {/*DÜĞÜN SALONU BİLGİLERİ BİTİŞ*/}
              <div className="row ps-2">
                <div className="col-3 pe-0">
                  {printData.aciklama && (
                    <>
                      <div
                        className="col fw-bold mt-3"
                        style={{ fontSize: "1.1em" }}
                      >
                        • AÇIKLAMA & EK BİLGİLER
                      </div>
                      <div
                        className="mt-1 ps-2"
                        style={{
                          maxHeight: "150px",
                        }}
                      >
                        {printData.aciklama}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="mt-auto mb-4">
                <hr className="" style={{ width: "95%" }} />

                <div className="row mt-3">
                  <div className="col-3 fs-5 fw-bold">SATICI:</div>
                  <div className="col-4">
                    <div className="row fs-5 fw-bold">ALICI:</div>
                    <div className="row">
                      <div className="col p-0 pe-1" style={{ height: "45px" }}>
                        {printData.damatAdi}
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row">
                      <div className="col p-0 fw-bold">TOPLAM:</div>
                      <div className="col p-0 mt-auto">
                        {printData.odemeToplam} ₺
                      </div>
                    </div>
                    <div className="row">
                      <div className="col p-0 fw-bold">KAPORA:</div>
                      <div className="col p-0 mt-auto ps-1">
                        {printData.odemeAlinan} ₺
                      </div>
                    </div>
                    {Number(printData.odemeIndirim) > 0 && (
                      <div className="row">
                        <div className="col p-0 fw-bold">İSKONTO:</div>
                        <div className="col p-0 mt-auto ps-1">
                          {printData.odemeIndirim} ₺
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col p-0 fw-bold">KALAN:</div>
                      <div className="col p-0 mt-auto">
                        {printData.odemeKalan} ₺
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
