import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

function NewLiteBridalContractSuccess() {
  return (
    <Fragment>
      <div className="container-md rounded-3 shadow-sm p-2 border bg-light my-4">
        <div className="row p-4 my-2">
          <div className="col fw-bold fs-4 text-center">
            <i className="bi bi-journal-check pe-2 text-success fs-3" />
            Üçlü Gelinlik Sözleşmesi Başarıyla Kaydedildi!
          </div>
        </div>
        <div className="row mb-5 justify-content-center">
          <div className="col-md-7 fw-meduim fs-5 text-center">
            Sözleşme bilgilerini görüntülemek, alınan ödemeyi güncellemek veya
            sözleşmeyi tekrardan yazdırmak için Anasayfaya dönebilir veya yeni
            bir sözleşme oluşturabilirsiz.
          </div>
        </div>
        <div className="row">
          <div className="col text-center pb-4">
            <Button
              className="shadow-sm fw-bold me-4"
              as={Link}
              variant="outline-success"
              to="/lite-bridal-contracts"
            >
              <i className="bi bi-house-fill me-1" />
              Anasayfa
            </Button>
            <Button
              className="shadow-sm fw-bold"
              as={Link}
              variant="outline-primary"
              to="/lite-bridal-contracts/new"
            >
              <i className="bi bi-plus-circle-fill me-1" />
              Yeni Sözleşme
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default NewLiteBridalContractSuccess;
